import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'reactstrap';

const NoAccess = ({ Module, access }): React.ReactElement => {
    if (!Module) return null;
    return (
        <div>
            <Row>
                <Col md={{ size: 12 }} style={{ textAlign: 'center', marginTop: '22px' }}>
                    <FontAwesomeIcon icon={faLock} size={'9x'} />
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 12 }} style={{ textAlign: 'center' }}>
                    <h1>You are not authorized to use SIE {Module} </h1>
                    <p>If you believe you should be authorized:</p>
                    <p>
                        For Equinor Users submit an enquiry in
                        <a target="_blank" rel="noopener noreferrer" href="https://equinor.service-now.com/selfservice">
                            {' '}
                            Services@Equinor
                        </a>
                        {' '}
                        or apply for {access} access in
                        <a target="_blank" rel="noopener noreferrer" href="https://accessit.equinor.com/">
                            {' '}
                            AccessIT.
                        </a>
                    </p>
                    <p>
                        For External Users send mail to
                        <a href="mailto:tiif@equinor.com"> tiif@equinor.com</a> (cc your Equinor project contact)
                    </p>
                </Col>
            </Row>
        </div>
    );
};

export default NoAccess;
