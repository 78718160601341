import _ from 'lodash';
import { adAccessToken, authError, ensureValidToken } from '../AuthUtils';
import config from '../Config.json';
import ApiResponse from '../models/ApiResponse';
import { Facility } from '../models/facility';
import Project from '../models/Project';
// import Project from '../models/project';
import { PublicConfig } from '../models/PublicConfig';
import { SieSearchRequest } from '../models/SieSearchRequest';
import { SieUpdateRequest } from '../models/SieUpdateRequest';
import User from '../models/User';

// TODO: make proper models of the DTO's

const queryFromArray = (params: any[]) => {
    let query = '';
    params = params || [];
    if (params.length > 0) query = '?';
    let firstSet = true;
    for (let i = 0; i < params.length; i++) {
        if (params[i].value !== null && params[i].value !== undefined) {
            if (i > 0 && !firstSet) query += '&';
            firstSet = false;
            query += params[i].name + '=' + encodeURIComponent(params[i].value);
        }
    }

    return query;
};

const encodeValue = (value: any) => {
    if (value && value.length && value.length > 0 && value[0].value !== undefined) {
        return encodeURIComponent(
            _.without(
                value.map((i: { value: any }) => i.value),
                null
            ).join(',')
        );
    }

    return encodeURIComponent(value);
};

const queryFromObject = (object: { [x: string]: any }) => {
    let query = '';
    const props = Object.getOwnPropertyNames(object);

    if (props.length > 0) query = '?';

    let firstSet = true;

    for (let i = 0; i < props.length; i++) {
        if (object[props[i]] !== null && object[props[i]] !== undefined) {
            if (i > 0 && !firstSet) query += '&';
            firstSet = false;
            query += props[i] + '=' + encodeValue(object[props[i]]);
        }
    }

    return query;
};

export const buildQuery = (params: any) => {
    let query = '';

    if (Array.isArray(params)) {
        query = queryFromArray(params);
    } else {
        query = queryFromObject(params);
    }
    if (params.length > 0) query = '?';
    let firstSet = true;
    for (let i = 0; i < params.length; i++) {
        if (params[i].value !== null) {
            if (i > 0 && !firstSet) query += '&';
            firstSet = false;
            query += params[i].name + '=' + encodeURIComponent(params[i].value);
        }
    }
    return query;
};

export const apiRequest = async <T extends unknown>(
    url: string,
    params: string[] = [],
    acceptHeader: string | undefined = undefined,
    testurl: string | undefined = undefined,
    method: string | undefined = undefined,
    payload: any | undefined = undefined
): Promise<T> => {
    const query = buildQuery(params);
    return new Promise(function (resolve, reject) {
        ensureValidToken()
            .then(() => {
                const uri = testurl ? testurl : `${config.apiBase}${url}${query}`;
                return fetch(uri, {
                    method: method ? method : 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${adAccessToken}`,
                        Accept: acceptHeader ? acceptHeader : 'application/json'
                    },
                    body: payload ? JSON.stringify(payload) : undefined
                })
                    .then((response: any) => {
                        return handleResponse(response, uri);
                    })
                    .then((data: any) => {
                        return resolve(data);
                    })
                    .catch((error: any) => {
                        return reject({ error });
                    });
            })
            .catch((_exception) => {
                return reject({ error: authError });
            });
    });
};

export const apiRequestNoAuth = async <T extends ApiResponse>(
    url: string,
    params: string[] = [],
    acceptHeader: string | undefined = undefined,
    testurl: string | undefined = undefined,
    method: string | undefined = undefined
): Promise<T> => {
    params = params || [];
    const query = buildQuery(params);
    return new Promise(function (resolve, reject) {
        const uri = testurl ? testurl : `${config.apiBase}${url}${query}`;
        return fetch(uri, {
            method: method ? method : 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: acceptHeader ? acceptHeader : 'application/json'
            }
        })
            .then((response) => {
                return handleResponse(response, uri);
            })
            .then((data) => {
                return resolve(data);
            })
            .catch((error) => {
                return reject({ error });
            });
    });
};

const handleResponse = (response: any, uri: string | undefined = undefined, acceptHeader: string | undefined = undefined) => {
    if (response.error) return response;
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
        if (response.status !== 200) {
            if (response.status === 404) return { error: `Resource ${uri} was not found` };
            return response.json().then((data: { error: any }) => (data.error ? data : { error: data }));
        } else return response.json();
    } else if (contentType && contentType.indexOf(acceptHeader) !== -1) {
        return response.blob().then((blob: BlobPart) => {
            const newBlob = new Blob([blob], { type: acceptHeader });
            const disposition = response.headers.get('content-disposition');
            let filename = '';
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            const data = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = data;
            link.download = filename;
            link.click();
            setTimeout(function () {
                window.URL.revokeObjectURL(data);
            }, 100);
        });
    } else {
        return response.text().then((data: any) => {
            if (response.status === 404) return { error: `Resource ${uri} was not found` };
            if (response.status === 500) return { error: `Resource ${uri} is not working` };
            if (response.status === 200) return { error: null };
            return {
                error: response.status + ': ' + (data ? data : response.statusText)
            };
        });
    }
};

export const userInfo = (): Promise<User> => {
    return apiRequest<User>('/user');
};

export const clientConfig = (): Promise<any> => {
    return apiRequest<any>('/config/client');
};

export const clientPublicConfig = (): Promise<PublicConfig> => {
    return apiRequestNoAuth('/config/public');
};

export const getNewsForType = (type): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>(`/news/type/${type}`);
};

export const getFunctionCodes = (facility: string): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>(`/functionType/${facility}`);
};

export const getFunctionCodesForSearch = (): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/functionType/search');
};

export const getProjects = (facility: string): Promise<Array<Project> | ApiResponse> => {
    return apiRequest<ApiResponse>(`/projects/${facility}`);
};

export const GetAllProjectFromRequest = (): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/projects');
};

export const getFacilities = (): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/facility/');
};

export const getSieEnabledFacilities = (): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/facility/sieEnabled');
};

export const getSearchFacilities = (): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/facility/AllSieFacilities');
};

export const getSieDisabledFacilities = (): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/facility/sieDisabled');
};

export const FacilityUpdate = (facility: Facility): Promise<Facility> => {
    return apiRequest<Facility>('/facility/FacilityUpdate', undefined, undefined, undefined, 'POST', facility);
};

export const GetAllContractorCodes = (): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/ContractorCode');
};

export const getContractorCodes = (facility: string): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>(`/ContractorCode/${facility}`);
};

export const getContractorCodesForSelect = (startsWith, facilityName, max): Promise<any | ApiResponse> => {
    startsWith = startsWith || '';
    facilityName = facilityName || '';
    max = max || 0;
    return apiRequest<ApiResponse>(`/ContractorCode/StartsWith?startsWith=${startsWith}&facilityName=${facilityName}&max=${max}`);
};

export const getContractorCodesForSearch = (startsWith, facilityName, max): Promise<any | ApiResponse> => {
    startsWith = startsWith || '';
    facilityName = facilityName || '';
    max = max || 0;
    return apiRequest<ApiResponse>(`/ContractorCode/search?startsWith=${startsWith}&facilityName=${facilityName}&max=${max}`);
};

export const getSapEquipmentNumbers = (facility: string): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>(`/SapEquipmentNo/${facility}`);
};

export const getSapEquipmentNumbersForSelect = (startsWith, max): Promise<any | ApiResponse> => {
    startsWith = startsWith || '';
    max = max || 0;
    return apiRequest<ApiResponse>(`/SapEquipmentNo/StartsWith?startsWith=${startsWith}&max=${max}`);
};

export const allocateSieNumbers = (allocationRequest): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/allocation/allocate', undefined, undefined, undefined, 'POST', allocationRequest);
};

export const previewSieNumbers = (allocationRequest): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/allocation/preview', undefined, undefined, undefined, 'POST', allocationRequest);
};

export const rangePreview  = (allocationRequest): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/allocation/rangepreview', undefined, undefined, undefined, 'POST', allocationRequest);
};

export const getSieNumbers = (searchRequest: SieSearchRequest): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/sieObject', undefined, undefined, undefined, 'POST', searchRequest);
};

export const getFacilityByName = (name: string): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>(`/facility/getByName/${name}`);
}

export const getFacilityByUser = (): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/facility/GetAllocateFacilitiesForUser/');
}

export const getSieObject = (sieObjectId: string): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>(`/sieObject/getById/${sieObjectId}`);
}

export const SieObjectUpdate = (sieUpdateRequest: SieUpdateRequest): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/sieObject/SieObjectUpdate', undefined, undefined, undefined, 'POST', sieUpdateRequest);
}

export const addProjectPrefix = (projectPrefix): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/prefix', undefined, undefined, undefined, 'POST', projectPrefix);
};

export const getProjectPrefixesAllocation = (facility: any): Promise<any | ApiResponse> => {
    facility = facility || '';
    return apiRequest<ApiResponse>(`/prefix/getForAllocation?facility=${facility}`);
};

export const getProjectPrefixesSearch = (facility: any): Promise<any | ApiResponse> => {
    facility = facility || '';
    return apiRequest<ApiResponse>(`/prefix/getForSearch?facility=${facility}`);
};

export const updateProjectPrefix = (projectPrefix): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/prefix', undefined, undefined, undefined, 'PUT', projectPrefix);
};

export const updateFromCommonLib = (): Promise<any | ApiResponse> => {
    return apiRequest<ApiResponse>('/admin');
};
