import React from 'react';
import { useTitle } from '../CustomHooks';

const NoMatchPage = (): React.ReactElement => {
    useTitle('404');

    return (
        <h1 style={{ textAlign: 'center', fontSize: '400%' }}>
            <b>404</b>
            <br />
            Not found
        </h1>
    );
};

export default NoMatchPage;
