import React from 'react';
import { Container } from 'reactstrap';
import '../styles/app.scss';
import AlertBanner from './AlertBanner';
import Footer from './Footer';
import NavMenu from './NavMenu';
import Routes from './Routes';

const Layout = (props: { children?: React.ReactNode }): React.ReactElement => {
    return (
        <div className="main-container">
            <AlertBanner />
            <NavMenu />
            <Container className="content" fluid>
                {!!props.children ? props.children : <Routes />}
            </Container>
            <Footer />
        </div>
    );
};

export default Layout;
