import React, { useEffect, useState } from 'react';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table } from 'reactstrap';
import { useStoreActions, useStoreState } from '../../store/PortalStore';
import { SieObject } from '../../models/SieObject';
import { getContractorCodes, getProjects } from '../../services/Api';
import ApiResponse from '../../models/ApiResponse';
import Select from '../sie-components/Select';

const SieNumberDetailsEdit = ({
    sieObject,
    visible,
    setVisible
}: {
    sieObject: SieObject;
    visible: boolean;
    setVisible: (visible: boolean) => void;
}): React.ReactElement => {

    const superUserAccess = useStoreState(state => state.user.sieFunctions.sieSuperUser);

    const updateSieObject = useStoreActions(actions => actions.sieObject.updateSieObject);
    const updatingSieObject = useStoreState(state => state.sieObject.updatingSieObject);

    const errorUpdateSieObject = useStoreState(state => state.sieObject.updateError);
    const SetErrorUpdateSieObject =useStoreActions(actions => actions.sieObject.setUpdateError)

    const sieObjectUpdateSuccess =  useStoreState(state => state.sieObject.success);
    const setSieObjectUpdateSuccess = useStoreActions(actions => actions.sieObject.setSuccess)

    const [fetchingProjects, setFetchingProjects] = useState(false);
    const [projects, setProjects] = useState([]);

    const [fetchingContrCodes, setFetchingContrCodes] = useState(false);
    const [contractorCodes, setContractorCodes] = useState([]);

    const [facility, setFacility] = useState(undefined);
    const [sieNumber, setSieNumber] = useState('');
    const [contractorCode, setContractorCode] = useState(undefined);
    const [sapPlantNo, setSapPlantNo] = useState('');
    const [sapEquipmentNo, setSapEquipmentNo] = useState(undefined);
    const [status, setStatus] = useState(undefined);
    const [projectPrefix, setProjectPrefix] = useState('');
    const [project, setProject] = useState(undefined);
    const [functionType, setFunctionType] = useState('');
    const [error, setError] = useState(undefined);

    const toggleShow = (): void => {
        setVisible(!visible);
    };

    useEffect(() => {
        if (!sieObject) return;
        if (sieObject) {
            setSieNumber(sieObject.sieNumber);
            setFacility(sieObject.facility);
            setSapPlantNo(sieObject.sapPlantNo);
            setStatus(sieObject.status);
            setContractorCode(sieObject.contractorCode);
            setProjectPrefix(sieObject.projectPrefix);
            if (!project) setProject(sieObject.project);
            setFunctionType(sieObject.functionType);
            setSapEquipmentNo(sieObject?.sapEquipmentNo?.name);
            SetErrorUpdateSieObject(null)
            setSieObjectUpdateSuccess(false)
        }

        setFetchingProjects(true);
        getProjects(sieObject.facility).then((result) => {
            setProjects(result as ApiResponse[]);
            setFetchingProjects(false);
        });

        setFetchingContrCodes(true);
        getContractorCodes(sieObject.facility).then((result) => {
            setContractorCodes(result as ApiResponse[]);
            setFetchingContrCodes(false);
        });
    }, [visible])

    function updateTheSieObject() {
        const changedSieObject = {
            facility: facility ?? sieObject.facility,
            sieNumber: sieNumber ?? sieObject.sieNumber,
            project: project ?? sieObject.project,
            projectPrefix: projectPrefix ?? sieObject.projectPrefix,
            functionType: functionType ?? sieObject.functionType,
            contractorCode: contractorCode ?? sieObject.contractorCode,
            created: sieObject.created,
            sapEquipmentNo: sapEquipmentNo ?? sieObject?.sapEquipmentNo?.name,
            sapPlantNo: sapPlantNo ?? sieObject.sapPlantNo,
            status: status ?? sieObject.status,
            createdByTieApplication: sieObject.createdByTieApplication,
            createdByProject: sieObject.createdByProject,
            createdByFacility: sieObject.createdByFacility,
            createdByContrCode: sieObject.createdByContrCode,
            sieObjectId: sieObject.id
        }
        updateSieObject(changedSieObject)
            .catch(() => {
                setError('Error while updating number');

            }
            );
    }
    const selectProject = (selectedProject) => {
        if (selectedProject) {
            setProject(selectedProject.value)
        } else {
            setProject(sieObject.project);
        }
    };

    if (!sieObject) return null;

    return (
        <Modal size="lg" isOpen={visible} toggle={toggleShow}>
            <ModalHeader toggle={toggleShow}>Details for {sieObject.sieNumber}</ModalHeader>
            <ModalBody>
                {updatingSieObject ? <i><Spinner size="lg" /></i> : null}
                <Table striped>
                    <tbody>
                        <tr>
                            <td style={{ borderTop: 'none' }}>Facility</td>
                            <td style={{ borderTop: 'none' }}>{sieObject.facility}</td>
                        </tr>
                        <tr>
                            <td>SIE number</td>
                            <td>{sieObject.sieNumber}</td>
                        </tr>
                        <tr>
                            <td>Project</td>
                            <td>
                                <Select
                                    id="project"
                                    isLoading={fetchingProjects}
                                    defaultValue={{ value: sieObject.project , label: sieObject.project }}
                                    isMulti={false}
                                    formatOptionLabel={(option): string => option.label}
                                    getOptionValue={(option): string => option.value}
                                    getOptionLabel={(option): string => option.label}
                                    options={projects.map((p) => {
                                        return { label: `${p.name} | ${p.description}`, value: p.name };
                                    })}
                                    value={project?.value}
                                    onChange={(selectedProject) => selectProject(selectedProject)}
                                    isClearable={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>SIE Project prefix</td>
                            <td>{sieObject.projectPrefix}</td>
                        </tr>
                        <tr>
                            <td>Function type</td>
                            <td>{sieObject.functionType.toUpperCase()}</td>
                        </tr>
                        <tr>
                            <td>Contractor code</td>
                            <td>
                                <Select
                                    id="cc"
                                    isLoading={fetchingContrCodes}
                                    defaultValue={{ value: sieObject.contractorCode , label: sieObject.contractorCode }}
                                    isMulti={false}
                                    formatOptionLabel={(option): string => option.label}
                                    getOptionValue={(option): string => option.value}
                                    getOptionLabel={(option): string => option.label}
                                    options={contractorCodes.map((cc) => {
                                        return { label: `${cc.name} | ${cc.description}`, value: cc.name };
                                    })}
                                    value={contractorCode?.value}
                                    onChange={(contrCode) => setContractorCode(contrCode?.value)}
                                    isClearable={true}
                                /></td>
                        </tr>
                        <tr>
                            <td>SAP equipment number</td>
                            <td><input value={sapEquipmentNo ?? sieObject?.sapEquipmentNo?.name} onChange={(e) => setSapEquipmentNo(e.target.value)} /></td>
                        </tr>
                        {superUserAccess &&
                            <tr>
                                <td>SAP plant number</td>
                                <td><input value={sapPlantNo ?? sieObject.sapPlantNo} onChange={(e) => setSapPlantNo(e.target.value)} /></td>
                            </tr>
                        }
                        {!superUserAccess &&
                            <tr>
                                <td>SAP plant number</td>
                                <td>{sieObject.sapPlantNo}</td>
                            </tr>
                        }
                        <tr>
                            <td>Status</td>
                            <td>{sieObject.status}</td>
                        </tr>
                        <tr>
                            <td>Created by TIE application</td>
                            <td>{sieObject.createdByTieApplication}</td>
                        </tr>
                        <tr>
                            <td>Created by project</td>
                            <td>{sieObject.createdByProject}</td>
                        </tr>
                        <tr>
                            <td>Created by facility</td>
                            <td>{sieObject.createdByFacility}</td>
                        </tr>
                        <tr>
                            <td>Created by contractor code</td>
                            <td>{sieObject.createdByContrCode}</td>
                        </tr>
                        <tr>
                            <td>Created</td>
                            <td>{sieObject.created}</td>
                        </tr>
                    </tbody>
                </Table>
                <Alert color="danger" hidden={!errorUpdateSieObject}>
                    <b>Error updating: </b>
                    {errorUpdateSieObject}
                </Alert>
                {error && <Alert color="danger">{error}</Alert>}
                {sieObjectUpdateSuccess && <Alert color="success">  <b> {sieObject.sieNumber} updated successfully </b> </Alert>}
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={() => updateTheSieObject()} >
                    Save
                </Button>
                <Button color="primary" onClick={toggleShow}>
                    Close

                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default SieNumberDetailsEdit;
