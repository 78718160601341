import React from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import Layout from './components/Layout';
import NoAccess from './components/NoAccess';
import UnhandledExceptionBoundary from './components/UnhandledExceptionBoundary';
import { useStoreActions, useStoreState } from './store/PortalStore';

interface GettingState {
    gettingUserInfo: boolean;
    noAccess: boolean;
    gettingConfig: boolean;
    gettingConfigFailed: boolean;
}

const Getting = ({ gettingUserInfo, noAccess, gettingConfig, gettingConfigFailed }: GettingState): React.ReactElement => {
    return (
        <div>
            {gettingUserInfo ? (
                <Row>
                    <Col md={{ size: 12 }} style={{ textAlign: 'center' }}>
                        <span>
                            Getting user information...{'  '}
                            <Spinner size="sm" color="secondary" />
                        </span>
                    </Col>
                </Row>
            ) : null}
            {gettingConfig ? (
                <Row>
                    <Col md={{ size: 12 }} style={{ textAlign: 'center' }}>
                        Getting configuration...{'  '}
                        <Spinner size="sm" color="secondary" />
                    </Col>
                </Row>
            ) : null}

            {gettingConfigFailed && !noAccess ? (
                <Row>
                    <Col md={{ size: 12 }} style={{ textAlign: 'center' }}>
                        <span>Unable to fetch configuration</span>
                    </Col>
                </Row>
            ) : null}
            {noAccess ? (
                <NoAccess Module={'Portal'} access={''} />
            ) : null}
        </div>
    );
};

const App: React.FC = () => {

    const userInfo = useStoreState((state) => state.user.userInfo);
    const noAccess = useStoreState((state) => state.user.noAccess);
    const gettingUserInfo = useStoreState((state) => state.user.gettingUserInfo);
    const getUser = useStoreActions((actions) => actions.user.getUser);

    const config = useStoreState((state) => state.global.config);
    const gettingConfigFailed = useStoreState((state) => state.global.gettingConfigFailed);
    const gettingConfig = useStoreState((state) => state.global.gettingConfig);
    const getConfig = useStoreActions((actions) => actions.global.getConfig);

    if (!gettingConfig && config === null && !gettingConfigFailed) {
        getConfig();
    }
    if (!gettingUserInfo && userInfo == null && noAccess === false) {
        getUser();
    }

    if (userInfo === null || gettingUserInfo || noAccess || gettingConfig || gettingConfigFailed)
        return (
            <UnhandledExceptionBoundary>
                <Layout>
                    <Getting gettingUserInfo={gettingUserInfo} noAccess={noAccess} gettingConfig={gettingConfig} gettingConfigFailed={gettingConfigFailed} />
                </Layout>
            </UnhandledExceptionBoundary>
        );

    return (
        <UnhandledExceptionBoundary>
            <Layout />
        </UnhandledExceptionBoundary>
    );
};

export default App;
