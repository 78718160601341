const Logo = () => (
    <svg width="34" height="40" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3.78342 8.50371L3.78372 19.2466C3.78342 19.565 3.94451 19.8592 4.22276 20.018L13.614 25.3808C14.0124 25.6083 14.5205 25.3234 14.5208 24.8674V14.1249C14.5211 13.8064 14.3498 13.5122 14.0713 13.3532L4.68032 7.99062C4.28163 7.76311 3.78402 8.04809 3.78342 8.50371ZM32.7092 0.115065L19.1888 7.83598C18.7883 8.06468 18.5414 8.48801 18.542 8.94629V24.4127C18.5426 25.0692 19.274 25.4792 19.8475 25.1515L33.3682 17.4312C33.7687 17.2022 34.0003 16.7789 33.9997 16.3206L34 0.854186C33.9994 0.197717 33.283 -0.21228 32.7092 0.115065ZM14.1622 33.0099L10.4048 35.1559C10.2936 35.2196 10.2249 35.3372 10.2249 35.4646L10.2246 39.7624C10.2246 39.9452 10.4284 40.059 10.5877 39.968L14.3451 37.8223C14.4565 37.7589 14.5211 37.641 14.5208 37.514V33.2155C14.5208 33.0333 14.3215 32.9193 14.1622 33.0099ZM11.9724 29.0441L6.34531 25.8166C6.17854 25.7212 5.97292 25.7212 5.80615 25.8166L0.179096 29.0441C-0.0596985 29.181 -0.0596985 29.5226 0.179096 29.6597L5.80615 32.8867C5.97292 32.9827 6.17854 32.9827 6.34531 32.8867L11.9724 29.6597C12.2112 29.5226 12.2112 29.181 11.9724 29.0441ZM21.0313 29.626L23.5316 31.0598C23.6796 31.1448 23.8622 31.1448 24.0104 31.0598L26.5104 29.626C26.7226 29.5039 26.7226 29.2003 26.5104 29.0785L24.0104 27.6447C23.8622 27.5597 23.6796 27.5597 23.5316 27.6447L21.0313 29.0785C20.8191 29.2003 20.8191 29.5039 21.0313 29.626ZM19.0202 33.0769L21.5229 34.5059C21.6712 34.5907 21.7626 34.7477 21.7626 34.9174L21.7596 37.7803C21.7593 38.0232 21.4939 38.1752 21.2814 38.054L18.7787 36.6249C18.6305 36.5402 18.5423 36.3826 18.5423 36.2135L18.542 33.3506C18.542 33.108 18.8077 32.9554 19.0202 33.0769Z"
            fill="#FF1243"
        />
    </svg>
);

export default Logo;
