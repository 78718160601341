import { Error } from '../models/Error';
import { Action, action, thunk, Thunk } from 'easy-peasy';
import * as api from '../services/Api';
import { createError } from '../components/sie-components/Errors';
import UserProfile from '../models/UserProfile';
import UserInfo from '../models/UserInfo';
import SieFunctions from '../models/SieFunctions';

export interface UserModel {
    name: string;
    userInfo: UserInfo;
    profile: UserProfile;
    sieFunctions: SieFunctions;
    gettingUserInfo: boolean;
    noAccess: boolean;
    setSieFunctions: Action<UserModel, SieFunctions>;
    setError: Action<UserModel, Error | any>;
    setProfile: Action<UserModel, UserProfile>;
    setUserInfo: Action<UserModel, UserInfo>;
    setNoAccess: Action<UserModel, boolean>;
    setGettingUserInfo: Action<UserModel, boolean>;
    getUser: Thunk<UserModel>;
}

const user: UserModel = {
    name: 'Unknown user',
    profile: null,
    userInfo: null,
    sieFunctions: null,
    gettingUserInfo: false,
    noAccess: false,
    setError: action((_, __) => { }),


    // actions
    setProfile: action((state, payload) => {
        state.profile = payload;
    }),


    setUserInfo: action((state, payload) => {
        state.userInfo = payload;
    }),


    getUser: thunk(async (actions, _payload, _helper) => {
        actions.setGettingUserInfo(true);
        await api
            .userInfo()
            .then(async (User) => {
                if (!User || User.error) {
                    actions.setError(createError('Global', 'Could not get user info', 'No data returned from server'));
                    actions.setNoAccess(true);
                } else {
                    actions.setSieFunctions(User.sieFunctions)
                    actions.setUserInfo(User.userInfo);
                }
            })
            .catch((reason) => {
                actions.setError(createError('Global', 'Could not get user info', reason));
                actions.setNoAccess(true);
            })
            .finally(() => {
                actions.setGettingUserInfo(false);
            });
    }),

    setGettingUserInfo: action((state, payload) => {
        state.gettingUserInfo = payload;
    }),


    setSieFunctions: action((state, payload) => {
        state.sieFunctions = payload;
    }),

    setNoAccess: action((state, payload) => {
        state.noAccess = payload;
    })
};

export default user;
