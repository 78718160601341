import { Error } from '../models/Error';
import { Action, action, Thunk, thunk, ActionOn, actionOn } from 'easy-peasy';
import _ from 'lodash';
import * as api from '../services/Api';
import { createError } from '../components/sie-components/Errors';
import { StoreModel } from './PortalStore';

export interface GlobalModel {
    errors: Error[];
    config: any;
    gettingConfig: boolean;
    gettingConfigFailed: boolean;
    setError: Action<GlobalModel, Error | any>;
    removeError: Action<GlobalModel, Error>;
    setConfig: Action<GlobalModel, any>;
    setGettingConfig: Action<GlobalModel, boolean>;
    setGettingConfigFailed: Action<GlobalModel, boolean>;
    getConfig: Thunk<GlobalModel>;
    onConfigSet: ActionOn<GlobalModel, StoreModel>;
}

const global: GlobalModel = {
    errors: [],
    config: null,
    gettingConfig: false,
    gettingConfigFailed: false,

    setError: action((_state, _payload) => {}),
    removeError: action((state, payload) => {
        if (!payload) {
            return;
        }
        _.remove(state.errors, function (e) {
            return e.id === payload.id;
        });
    }),

    // Update/add to this
    onConfigSet: actionOn(
        (_actions, storeActions) => [storeActions.global.setError, storeActions.user.setError],
        (state, target) => {
            if (!target.payload) return;

            if (target.payload.module && target.payload.description) {
                // Already formatted error
                target.payload.id = state.errors.length + 1;
                state.errors.push(target.payload);
            } else if (target.payload?.error?.startsWith('401:')) {
                const e = createError('Global', 'Unauthorized', 'You are not authorized to view/edit this resource');
                e.id = state.errors.length + 1;
                state.errors.push(e);
            } else if (target.payload?.error?.startsWith('403:')) {
                const e = createError('Global', 'Forbidden', 'You are not authorized to view/edit this resource');
                e.id = state.errors.length + 1;
                state.errors.push(e);
            } else {
                const e = createError('Global', 'An error occurred', target.payload);
                e.id = state.errors.length + 1;
                state.errors.push(e);
            }
        }
    ),

    setConfig: action((state, payload) => {
        state.config = payload;
    }),

    setGettingConfig: action((state, payload) => {
        state.gettingConfig = payload;
    }),

    setGettingConfigFailed: action((state, payload) => {
        state.gettingConfigFailed = payload;
    }),

    getConfig: thunk(async (actions, _payload, _helper) => {
        actions.setGettingConfig(true);

        await api
            .clientConfig()
            .then((config) => {
                if (!config) {
                    actions.setGettingConfigFailed(true);

                    actions.setError(createError('Global', 'Could not retrieve configuration', 'No response from server'));
                } else {
                    actions.setConfig(config);
                }
            })
            .catch((reason) => {
                actions.setGettingConfigFailed(true);

                actions.setError(createError('Global', 'Could not retrieve configuration', reason));
            })
            .finally(() => {
                actions.setGettingConfig(false);
            });
    })
};

export default global;
