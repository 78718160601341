import { faCheckCircle, faExclamationCircle, faExclamationTriangle, faInfoCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Alert } from 'reactstrap';
import { useMountEffect } from '../CustomHooks';
import ApiResponse from '../models/ApiResponse';
import { AlertSeverity, NewsEntry, NewsType } from '../models/NewsEntry';
import { getNewsForType } from '../services/Api';

const AlertBanner = (): ReactElement => {
    const [alerts, setAlerts] = useState([]);

    useMountEffect(() => {
        getNewsForType(NewsType.SieAlert).then((result) => {
            if ((result as ApiResponse).error) {
                console.error(result as ApiResponse);
            } else {
                setAlerts(result as NewsEntry[]);
            }
        });
    });

    const getAlertColor = (alert: NewsEntry): string => {
        switch (alert.alertSeverity) {
            case AlertSeverity.Info:
                return 'info';
            case AlertSeverity.Success:
                return 'success';
            case AlertSeverity.Warning:
                return 'warning';
            case AlertSeverity.Error:
                return 'danger';
            default:
                return 'secondary';
        }
    };

    const getAlertIcon = (alert: NewsEntry): IconDefinition => {
        switch (alert.alertSeverity) {
            case AlertSeverity.Info:
                return faInfoCircle;
            case AlertSeverity.Success:
                return faCheckCircle;
            case AlertSeverity.Warning:
                return faExclamationTriangle;
            case AlertSeverity.Error:
                return faExclamationCircle;
            default:
                return faInfoCircle;
        }
    };

    if (!alerts || alerts.length === 0) return null;

    return (
        <div>
            {alerts.map((alert) => (
                <Alert key={alert.id} color={getAlertColor(alert)} style={{ borderRadius: 0, margin: 0 }}>
                    <FontAwesomeIcon icon={getAlertIcon(alert)} /> <b>{alert.title}</b>
                    {alert.content?.length > 0 && <ReactMarkdown>{alert.content}</ReactMarkdown>}
                </Alert>
            ))}
        </div>
    );
};

export default AlertBanner;
