import React, { useEffect, useState } from 'react';
import { Button, CustomInput, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table } from 'reactstrap';
import { Facility } from '../../models/facility';
import { useStoreActions, useStoreState } from '../../store/PortalStore';
import { RenderError } from '../sie-components/Errors';

const FacilityDetails = ({
    facility,
    visible,
    setVisible
}: {
    facility: Facility;
    visible: boolean;
    setVisible: (visible: boolean) => void;
}): React.ReactElement => {

    const saveFacility = useStoreActions(actions => actions.admin.saveFacility);
    const savingFacility = useStoreState(state => state.admin.savingFacility);

    const errorSaveFacility = useStoreState(state => state.admin.savingFacilityError);
    const setErrorSaveFacility = useStoreActions(actions => actions.admin.setSavingFacilityError);

    // const facilitySaveSuccess = useStoreState(state => state.admin.savingFacilitySuccess);
    const setFacilitySaveSuccess = useStoreActions(actions => actions.admin.setSavingFacilitySuccess)

    const [valid, setValid] = useState(undefined);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [sapPlantNo, setSapPlantNo] = useState('');
    const [error, setError] = useState('');

    const close = (): void => {
        setVisible(!visible);
    };

    const toggleCheckbox = (): void => {
        setValid(!valid)
    }

    useEffect(() => {
        if (facility) {
            setErrorSaveFacility(null);
            setFacilitySaveSuccess(null);
            setName(facility.name);
            setDescription(facility.description);
            setSapPlantNo(facility.sapPlantNo);
            setValid(facility.valid);
        }
    }, [facility])

    function SieChange() {
        setError('');

        saveFacility({
            id: facility.id,
            name: name ?? facility.name,
            description: description ?? facility.description,
            created: facility.created,
            updated: facility.updated,
            sapPlantNo: sapPlantNo ?? facility.sapPlantNo,
            valid: valid
        })
    }

    if (!facility) return null;

    return (
        <Modal size="lg" isOpen={visible} toggle={close}>
            <ModalHeader toggle={close}>Details for {facility.name}</ModalHeader>
            <ModalBody>
                {error ? <i>{error}</i> : null}

                {savingFacility ? <Spinner size="sm" /> : null}
                <Table striped>
                    <tbody>
                        <tr>
                            <td>Facility</td>
                            <td>{name}</td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td><Input value={description} onChange={(e) => setDescription(e.target.value)} /></td>
                        </tr>
                        <tr>
                            <td>Created</td>
                            <td title={facility.created} > {facility.created ? new Date(Date.parse(facility.created)).toLocaleString('en-GB') : 'err'}</td>
                        </tr>
                        <tr>
                            <td>Updated</td>
                            <td title={facility.updated} > {facility.updated ? new Date(Date.parse(facility.updated)).toLocaleString('en-GB') : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>SAP Plant number</td>
                            <td><Input value={sapPlantNo} onChange={(e) => setSapPlantNo(e.target.value)} /></td>
                        </tr>
                        <tr>
                            <td>SIE Enabled</td>
                            <td className="ml-3 mt-2">
                                <CustomInput onChange={() => toggleCheckbox()} type="switch" id={facility.id} checked={valid} />
                            </td>

                        </tr>
                    </tbody>
                </Table>
                {errorSaveFacility && <RenderError error={errorSaveFacility} toggle={(): void => setErrorSaveFacility(null)} />}

            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={() => SieChange()} >
                    Save
                </Button>
                <Button color="secondary" onClick={close}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default FacilityDetails;
