import { createStore, createTypedHooks } from 'easy-peasy';
import admin, { AdminModel } from './AdminModel';
import global, { GlobalModel } from './GlobalModel';
import sieObject, { SieObjectModel } from './SieObjectModel';
import user, { UserModel } from './UserModel';

export interface StoreModel {
    global: GlobalModel;
    user: UserModel;
    admin: AdminModel;
    sieObject: SieObjectModel;
}

export const model: StoreModel = {
    global,
    user,
    admin,
    sieObject
};

export const { useStoreActions, useStore, useStoreDispatch, useStoreState } = createTypedHooks<StoreModel>();

export default (u) => {
    user.profile = u ? u : undefined;
    user.name = u ? u.name : undefined;
    return createStore(model);
};
