import { Error } from '../models/Error';
import { Action, action, thunk, Thunk } from 'easy-peasy';
import * as api from '../services/Api';
import { createError } from '../components/sie-components/Errors';
import { Facility } from '../models/facility';
import _ from 'lodash';
import ApiResponse from '../models/ApiResponse';

export interface AdminModel {
    sieEnabledFacilities: Array<Facility>;
    fetchingSieEnabledFacilities: boolean;
    fetchSieEnabledFacilities: Thunk<AdminModel, any>;
    setFetchingSieEnabledFacilities: Action<AdminModel, boolean>;
    setSieEnabledFacilities: Action<AdminModel, Array<Facility>>;

    setError: Action<AdminModel, Error | any>;
    error: Error;

    sieDisabledFacilities: Array<Facility>;
    fetchingSieDisabledFacilities: boolean;
    fetchSieDisabledFacilities: Thunk<AdminModel, any>;
    setFetchingSieDisabledFacilities: Action<AdminModel, boolean>;
    setSieDisabledFacilities: Action<AdminModel, Array<Facility>>;

    savingFacility: boolean;
    selectedFacility: Facility;
    setSavingFacility: Action<AdminModel, boolean>;
    setSelectedFacility: Action<AdminModel, Facility>;
    saveFacility: Thunk<AdminModel, Facility>;
    syncSavedFacility: Action<AdminModel, Facility>;

    savingFacilityError: Error;
    setSavingFacilityError: Action<AdminModel, Error | any>;
    savingFacilitySuccess: boolean;
    setSavingFacilitySuccess: Action<AdminModel, boolean>;

    updateFromCommonLib: Thunk<AdminModel>;
    setUpdatingFromCommonLib: Action<AdminModel, boolean>;
    setUpdatingFromCommonLibError: Action<AdminModel, Error | any>;
    updatingFromCommonLib: boolean;
    updatingFromCommonLibError: Error;
}

const admin: AdminModel = {
    error: null,
    sieEnabledFacilities: null,
    savingFacilitySuccess: null,
    savingFacilityError: null,
    fetchingSieEnabledFacilities: false,
    updatingFromCommonLib: false,
    updatingFromCommonLibError: null,
    setFetchingSieEnabledFacilities: action((state, payload) => {
        state.fetchingSieEnabledFacilities = payload;
    }),

    setSieEnabledFacilities: action((state, payload) => {
        state.sieEnabledFacilities = payload;
    }),

    setSavingFacilityError: action((state, payload) => {
        state.savingFacilityError = payload;
    }),

    setSavingFacilitySuccess: action((state, payload) => {
        state.savingFacilitySuccess = payload;
    }),

    setUpdatingFromCommonLib: action((state, payload) => {
        state.updatingFromCommonLib = payload;
    }),

    setUpdatingFromCommonLibError: action((state, payload) =>{
        state.updatingFromCommonLibError = payload;
    }),


    setError: action((state, payload) => {
        state.error = payload;
    }),

    fetchSieEnabledFacilities: thunk(async (actions, _p, _s) => {
        actions.setFetchingSieEnabledFacilities(true);
        await api
            .getSieEnabledFacilities()
            .then(async (res) => {
                if (!res || res.error) {
                    actions.setSavingFacilityError(res.error)
                } else {
                    actions.setSieEnabledFacilities(res);
                }
            })
            .catch((reason) => {
                actions.setError(createError('Admin', 'Could not get facilities', reason));
            })
            .finally(() => {
                actions.setFetchingSieEnabledFacilities(false);
            });
    }),

    sieDisabledFacilities: null,
    fetchingSieDisabledFacilities: false,
    setFetchingSieDisabledFacilities: action((state, payload) => {
        state.fetchingSieDisabledFacilities = payload;
    }),
    setSieDisabledFacilities: action((state, payload) => {
        state.sieDisabledFacilities = payload;
    }),
    fetchSieDisabledFacilities: thunk(async (actions, _p, _s) => {
        actions.setFetchingSieDisabledFacilities(true);
        await api
            .getSieDisabledFacilities()
            .then(async (res) => {
                if (!res || res.error) {
                    actions.setError(createError('Admin', 'Could not get facilities', ''));
                } else {
                    actions.setSieDisabledFacilities(res);
                }
            })
            .catch((reason) => {
                actions.setError(createError('Admin', 'Could not get facilities', reason));
            })
            .finally(() => {
                actions.setFetchingSieDisabledFacilities(false);
            });
    }),

    savingFacility: false,
    selectedFacility: null,
    saveFacility: thunk(async (actions, facility, __) => {
        actions.setSavingFacility(true);
        actions.setSavingFacilityError(null);
        actions.setSavingFacilitySuccess(false);
        await api
            .FacilityUpdate(facility)
            .then(async (res) => {
                if (!res || res.error) {
                    actions.setSavingFacilityError(res.error);
                    actions.setSavingFacilitySuccess(false);
                } else {
                    actions.syncSavedFacility(res);
                    actions.setSavingFacilitySuccess(true);
                }
            })
            .catch((reason) => {
                actions.setSavingFacilityError(createError('Admin', 'Could not save facilities', reason));
            })
            .finally(() => {
                actions.setSavingFacility(false);
            });
    }),

    updateFromCommonLib: thunk(async (actions) => {
        actions.setUpdatingFromCommonLib(true);
        await api.updateFromCommonLib()
            .then(async (res) => {
                if ((res as ApiResponse)?.error) {
                    actions.setUpdatingFromCommonLibError(createError('Admin.UpdateFromCommonLib','Something failed when updating from Common Library', res));
                }
            })
            .catch((reason) => {
                actions.setUpdatingFromCommonLibError(createError('Admin.UpdateFromCommonLib','Something failed when updating from Common Library', reason))
            }).finally(() => {
                actions.setUpdatingFromCommonLib(false);
        });
    }),

    syncSavedFacility: action((state, updatedFacility) => {

        const toBeUpdatedInAllFacilities = _.findIndex(state.sieDisabledFacilities, f => {
            return f.id === updatedFacility.id
        }, 0);
        if (toBeUpdatedInAllFacilities >= 0) {
            _.merge(state.sieDisabledFacilities[toBeUpdatedInAllFacilities], updatedFacility)
        }

        const toBeUpdatedInSieFacilities = _.findIndex(state.sieEnabledFacilities, f => {
            return f.name === updatedFacility.name
        }, 0);

        if (toBeUpdatedInSieFacilities >= 0) {
            _.merge(state.sieEnabledFacilities[toBeUpdatedInSieFacilities], updatedFacility)
        } else if (updatedFacility.valid === true) {
            state.sieEnabledFacilities.push(updatedFacility);
        }
    }
    ),

    setSavingFacility: action((state, payload) => {
        state.savingFacility = payload;
    }),
    setSelectedFacility: action((state, payload) => {
        state.selectedFacility = payload;
    })
};

export default admin;
