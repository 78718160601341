import React from 'react';
import { Route } from 'react-router';
import UnhandledExceptionBoundary from './UnhandledExceptionBoundary';

export const RouteWithUnhandledExceptionBoundary = ({ component: Component, ...rest }): React.ReactElement => {
    return (
        <UnhandledExceptionBoundary>
            <Route
                {...rest}
                component={(props) => (
                    <UnhandledExceptionBoundary>
                        <Component {...props} />
                    </UnhandledExceptionBoundary>
                )}
            />
        </UnhandledExceptionBoundary>
    );
};
