import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { SieObject } from '../../models/SieObject';

const SieNumberDetails = ({
    sieObject,
    visible,
    setVisible
}: {
    sieObject: SieObject;
    visible: boolean;
    setVisible: (visible: boolean) => void;
}): React.ReactElement => {


    const toggleShow = (): void => {
        setVisible(!visible);
    };

    useEffect(() => {
        if (!sieObject) return;
    }, [visible])



    if (!sieObject) return null;

    return (
        <Modal size="lg" isOpen={visible} toggle={toggleShow}>
            <ModalHeader toggle={toggleShow}>Details for {sieObject.sieNumber}</ModalHeader>
            <ModalBody>
                <Table striped>
                    <tbody>
                        <tr>
                            <td style={{ borderTop: 'none' }}>Facility</td>
                            <td style={{ borderTop: 'none' }}>{sieObject.facility}</td>
                        </tr>
                        <tr>
                            <td>SIE number</td>
                            <td>{sieObject.sieNumber}</td>
                        </tr>
                        <tr>
                            <td>Project </td>
                            <td>{sieObject.project}</td>
                        </tr>
                        <tr>
                            <td>SIE Project prefix</td>
                            <td>{sieObject.projectPrefix}</td>
                        </tr>
                        <tr>
                            <td>Function type</td>
                            <td>{sieObject.functionType.toUpperCase()}</td>
                        </tr>
                        <tr>
                            <td>Contractor code</td>
                            <td>{sieObject.contractorCode}</td>
                        </tr>
                        <tr>
                            <td>SAP equipment number</td>
                            <td>{sieObject?.sapEquipmentNo?.name}</td>
                        </tr>
                        <tr>
                            <td>SAP plant number</td>
                            <td>{sieObject.sapPlantNo}</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>{sieObject.status}</td>
                        </tr>
                        <tr>
                            <td>Created by TIE application</td>
                            <td>{sieObject.createdByTieApplication}</td>
                        </tr>
                        <tr>
                            <td>Created by project</td>
                            <td>{sieObject.createdByProject}</td>
                        </tr>
                        <tr>
                            <td>Created by facility</td>
                            <td>{sieObject.createdByFacility}</td>
                        </tr>
                        <tr>
                            <td>Created by contractor code</td>
                            <td>{sieObject.createdByContrCode}</td>
                        </tr>
                        <tr>
                            <td>Created</td>
                            <td>{sieObject.created}</td>
                        </tr>
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggleShow}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default SieNumberDetails;
