import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileSaver from 'file-saver';
import React from 'react';
import { Button } from 'reactstrap';
import XLSX from 'xlsx';

export interface ExportProps {
    data: any[];
    filename: string;
    disabled?: boolean;
}

export const ExportExcel: React.FC<ExportProps> = (props) => {
    const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(props.data);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type });
        FileSaver.saveAs(data, props.filename + '.xlsx');
    };

    return (
        <Button
            style={{ marginLeft: '5px' }}
            disabled={props.disabled}
            color="info"
            title="Export data to Excel"
            onClick={() => exportToExcel()}>
            <FontAwesomeIcon icon={faFileExcel} />
        </Button>
    );
};
