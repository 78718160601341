import React from 'react';
import ReactSelect from 'react-select';

// https://github.com/JedWatson/react-select/issues/1537#issuecomment-590333086
const selectStyles = {
    menuPortal: (base: any): any => ({ ...base, zIndex: 9999 }),
    menu: (provided: any): any => ({ ...provided, zIndex: '9999 !important' })
};

const Select = ({ ...props }): React.ReactElement => {
    return <ReactSelect {...props} styles={selectStyles} />;
};

export default Select;
