import moment from 'moment';
import React from 'react';
import { Container } from 'reactstrap';
import { useStoreState } from '../store/PortalStore';

const Footer = (): React.ReactElement => {
    const config = useStoreState((state) => state.global.config);
    return (
        <footer className="footer">
            <Container fluid>
                <div className="d-flex justify-content-center">
                    <a href="https://equinor.service-now.com/selfservice">Report an error</a>
                </div>
                <div className="d-flex justify-content-center">
                    <p>
                        SIE Portal {config ? `Build ${config.buildVersion}` : null} © Equinor ASA, {moment().year()}{' '}
                        <a href="https://www.equinor.com/en/about-us/site-info/privacy-policy.html">Privacy Policy</a>
                    </p>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
