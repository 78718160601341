import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Alert, Button } from 'reactstrap';
import { LogException } from './sie-components/Logging';

const reload = (): void => {
    window.location.reload();
};

const UnhandledExceptionBoundary = (props: { children: React.ReactNode }): React.ReactElement => {
    return (
        <ErrorBoundary
            onError={(error: Error, info): void => {
                LogException(error, info.componentStack);
            }}
            fallbackRender={({ error, resetErrorBoundary }): React.ReactElement => {
                return (
                    <Alert color="danger">
                        <b>Unhandled error occurred</b>
                        <p>
                            This error was not handled properly, and caused the application to fail. Please notify the team about this, and let them know what you
                            did when this happened, and the contents of the message below.
                        </p>
                        <h3>{error.name}</h3>
                        <pre>{error.message}</pre>
                        <pre
                            style={{
                                display:
                                    // @ts-ignore
                                    window.appInsights ? 'none' : 'block'
                            }}>
                            {error.stack}
                        </pre>
                        <Button onClick={resetErrorBoundary} color="primary">
                            Reload and try again
                        </Button>{' '}
                        <Button onClick={reload} color="secondary">
                            Refresh page
                        </Button>
                    </Alert>
                );
            }}>
            {props.children}
        </ErrorBoundary>
    );
};

export default UnhandledExceptionBoundary;
