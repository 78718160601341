import React from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { useStoreState, useStoreActions } from '../../store/PortalStore';
import _ from 'lodash';
import { Error } from '../../models/Error';

export const formatError = (error: any) => {
    let formatted = error || 'Unknown error'; // TODO: check if this is good enough
    formatted = formatted.error || formatted.message || error;
    formatted = formatted.exceptionMessage || formatted.message || formatted;
    if (typeof formatted === 'string' || formatted instanceof String) return formatted;
    return JSON.stringify(formatted);
};

export const createError = (module: string, header: string, error: string | any, key: number | undefined = undefined, hide: boolean = false): Error => {
    return {
        id: key,
        module,
        header,
        description: formatError(error).toString(),
        hide
    };
};

export const getErrorsWithKey = (errors: any, key: any) => {
    return _.filter(errors, (i) => i.key === key);
};

export const getErrorsForModule = (errors: any, module: any) => {
    return _.filter(errors, (i) => i.module === module);
};

const Errors = ({ module }: any) => {
    const errors = useStoreState((state) => state.global.errors);
    const removeError = useStoreActions((actions) => actions.global.removeError);
    return (
        <Row>
            <Col md={12}>
                {getErrorsForModule(errors, module).map((e, i) => (
                    <RenderError toggle={removeError} error={e} key={i} />
                ))}
            </Col>
        </Row>
    );
};

const isInstanceOfError = (object: any): object is Error => {
    return 'description' in object;
};

export interface RenderErrorProps {
    error: Error;
    toggle?: (error: Error) => void;
}

export const RenderError: React.FC<RenderErrorProps> = (props) => {
    const e = props.error;

    if (typeof e === typeof '') {
        return (
            <Alert key={(e as unknown) as string} isOpen={true} color="danger">
                {e}
            </Alert>
        );
    }

    const errorHeader = isInstanceOfError(e) ? e.header : 'Unknown error occurred';
    const errorDescription = isInstanceOfError(e) ? e.description : (e as any).error ?? 'Please report this to the TIE-team';

    return (
        <Alert key={e.id} isOpen={true} toggle={(): void => props.toggle(e)} color="danger">
            <h5 className="alert-heading">{errorHeader}</h5>
            <p>{errorDescription}</p>
        </Alert>
    );
};

export default Errors;
