import { faWater } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Form, FormGroup, Label, Col, Table, Badge, Button, Spinner} from 'reactstrap';
import { useMountEffect, useTitle } from '../../CustomHooks';
import { Facility } from '../../models/facility';
import FacilityDetails from '../details/FacilityDetails';
import Select from '../sie-components/Select';
import { useStoreActions, useStoreState } from '../../store/PortalStore';

import NoAccess from '../NoAccess';
import ApiResponse from '../../models/ApiResponse';
import { RenderError } from '../sie-components/Errors';


const adminPage = (): React.ReactElement => {

    const access = useStoreState((state) => state.user.sieFunctions.sieAdmin);

    if (!access) {
        return (
            <NoAccess Module={'Admin'} access={'SIE Administrator (SIE PORTAL) or SIE superuser'} />
        );
    }

    const fetchSieEnabledFacilities = useStoreActions(actions => actions.admin.fetchSieEnabledFacilities);
    const sieEnabledFacilities = useStoreState(state => state.admin.sieEnabledFacilities);
    const fetchingSieEnabledFacilities = useStoreState(state => state.admin.fetchingSieEnabledFacilities);

    const error = useStoreState(state => state.admin.error);
    const setError = useStoreActions(actions => actions.admin.setError);

    const fetchSieDisabledFacilities = useStoreActions(actions => actions.admin.fetchSieDisabledFacilities);
    const sieDisabledFacilities = useStoreState(state => state.admin.sieDisabledFacilities);
    const fetchingSieDisabledFacilities = useStoreState(state => state.admin.fetchingSieDisabledFacilities);

    const [selected, setSelected] = useState<Facility>();
    const [facilityDetailsVisible, setFacilityDetailsVisible] = useState(false);

    const updateFromCommonLib = useStoreActions(actions => actions.admin.updateFromCommonLib);
    const commonLibUpdateError = useStoreState(state => state.admin.updatingFromCommonLibError);
    const setUpdatingFromCommonLibError = useStoreActions(actions => actions.admin.setUpdatingFromCommonLibError);
    const updatingFromCommonLib = useStoreState(state => state.admin.updatingFromCommonLib);

    useTitle('Admin');

    const handleUpdateClick = () => {
        updateFromCommonLib().then(async (result) => {
            if ((result as ApiResponse)?.error) {
                setUpdatingFromCommonLibError(result.error);
            }
        })
        .catch(() => {
            setUpdatingFromCommonLibError('Error while trying to update from Common Library');
        });
    };

    useMountEffect(() => {
        if (!sieEnabledFacilities && !fetchingSieEnabledFacilities) {
            fetchSieEnabledFacilities({});
        }
        if (!sieDisabledFacilities && !fetchingSieDisabledFacilities) {
            fetchSieDisabledFacilities({});
        }

        return () => {
        };
    });

    useEffect(() => {
            setFacilityDetailsVisible(!!selected);
        },
    [selected]);

    return (
        <>
            <h1>
                <FontAwesomeIcon icon={faWater} size="1x" /> SIE Portal Admin
            </h1>
            <Card>
            <CardHeader>
                    <h3>Common Library Functions</h3>
                </CardHeader>
                <CardBody>
                    <Form>
                        <FormGroup row>
                            <Label for="UpdateFromCommonLib" sm={2}>
                               Updates data from Common Library
                            </Label>
                            <Col sm={2}>
                                <Button id="UpdateFromCommonLib" color="primary" onClick={()=> handleUpdateClick()} disabled={updatingFromCommonLib === true}>
                                    {updatingFromCommonLib && <Spinner size="sm" /> }
                                    Update
                                </Button>
                            </Col>
                            {commonLibUpdateError && <RenderError error={commonLibUpdateError} toggle={(): void => setUpdatingFromCommonLibError(null)} />}
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>
            <Card className="mt-3">
                <CardHeader>
                    <h3>Enable new SIE facility</h3>
                </CardHeader>
                <CardBody>
                    <Form>
                        <FormGroup row>
                            <Label for="SieDisabledFacilities" sm={2}>
                                All facilities
                            </Label>
                            <Col sm={10}>
                                <Select
                                    id="SieDisabledFacilities"
                                    isLoading={fetchingSieDisabledFacilities}
                                    isMulti={false}
                                    formatOptionLabel={(option): string => `${option.name} | ${option.description}`}
                                    getOptionValue={(option): string => option.name}
                                    getOptionLabel={(option): string => option.fullName}
                                    options={sieDisabledFacilities ?? []}
                                    value={selected}
                                    onChange={(value) => setSelected(value)}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>
            <Card className="mt-3">
                <CardHeader>
                    <h4>SIE Enabled Facilities</h4>
                </CardHeader>
                <CardBody>
                    {fetchingSieEnabledFacilities ? <Spinner size="sm" /> : null}
                    {sieEnabledFacilities?.length === 0 && !fetchingSieEnabledFacilities && <i>No facilities found</i>}
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Facility </th>
                                        <th>Description</th>
                                        <th>Created</th>
                                        <th>Updated</th>
                                        <th>SAP Plant No</th>
                                        <th>SIE Enabled</th>
                                    </tr>
                                </thead>
                            {sieEnabledFacilities?.length > 0 && (
                                <tbody>
                                    {sieEnabledFacilities.map((n) => (
                                        <tr key={n.id}>
                                            <td>{n.name}</td>
                                            <td>{n.description}</td>
                                            <td title={n.created}>{new Date(Date.parse(n.created)).toLocaleString('en-GB') ?? null}</td>
                                            <td title={n.updated} >{n.updated? new Date(Date.parse(n.updated)).toLocaleString('en-GB') : 'N/A' }</td>
                                            <td>{n?.sapPlantNo}</td>
                                            <td><Badge color={n.valid ? 'success' : 'danger'}>{n.valid.toString()}</Badge></td>
                                            <td>
                                                <Button onClick={(): void => setSelected(n)}>
                                                    Edit
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                            </Table>
                </CardBody>
            </Card>
            {error && <RenderError error={error} toggle={(): void => setError(null)} />}
            <FacilityDetails facility={selected} visible={facilityDetailsVisible}  setVisible={(visible) => setFacilityDetailsVisible(visible)} />
        </>
    );
};

export default adminPage;
