import { faWater } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Alert, Card, CardBody, CardHeader, Col, Container, Row, Spinner } from 'reactstrap';
import { useMountEffect, useTitle } from '../../CustomHooks';
import ApiResponse from '../../models/ApiResponse';
import { NewsEntry, NewsType } from '../../models/NewsEntry';
import { getNewsForType } from '../../services/Api';
import NoAccess from '../NoAccess';
import { useStoreState } from '../../store/PortalStore';

const Home: React.FC = () => {
    const [fetchingNews, setFetchingNews] = useState(true);
    const [news, setNews] = useState([]);
    const [error, setError] = useState(undefined);

    useMountEffect(() => {
        getNewsForType(NewsType.SieNews).then((result) => {
            if ((result as ApiResponse).error) {
                setError(result.error);
            } else {
                setNews(result as NewsEntry[]);
            }

            setFetchingNews(false);
        });
    });

    useTitle('');

    const access = useStoreState((state) => state.user.sieFunctions.sieRead);

    if (!access) {
        return (
            <NoAccess Module={'Portal'} access={''} />
        );
    }

    return (
        <Container fluid style={{ marginBottom: 20 }}>
            <Row>
                <Col md={12}>
                    <h1>
                        <FontAwesomeIcon icon={faWater} size="1x" /> SIE Portal
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={{ size: 8, offset: 2 }}>
                    {error && <Alert color="danger">{error}</Alert>}
                    {fetchingNews && <Spinner size="lg" />}
                    {news &&
                        news.map((newsEntry: NewsEntry) => (
                            <Card style={{ marginTop: '1em' }} key={newsEntry.id}>
                                <CardHeader>
                                    <b>{newsEntry.title}</b>
                                    <span className="text-muted" style={{ float: 'right' }}>
                                        <i>
                                            {newsEntry.publishedBy}
                                            {' @ '}
                                            {new Date(newsEntry.publishDate).toDateString()}
                                        </i>
                                    </span>
                                </CardHeader>
                                <CardBody>
                                    <ReactMarkdown>{newsEntry.content}</ReactMarkdown>
                                </CardBody>
                            </Card>
                        ))}
                </Col>
            </Row>
        </Container>
    );
};

export default Home;
