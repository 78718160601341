import * as Msal from 'msal';
import * as api from './services/Api';

export let msalApp: any;

export let adAccount: Msal.Account;
export let adAccessToken;
export let authError;
export let noAccess = false;
export let redirecting = false;
let adClientId;
let adTenantId;

const createSignInRequest = (): Msal.AuthenticationParameters => {
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/521
    return {
        scopes: [`${adClientId}/.default`],
        authority: `https://login.microsoftonline.com/${adTenantId}`,
        redirectUri: window.location.origin,
        forceRefresh: false
    };
};

export const isIE = (): boolean => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ') > -1;
    const msie11 = ua.indexOf('Trident/') > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};

const loggerCallback = (level, message): void => {
    console.log(level, message);
};

const initMsal = (clientId, tenantId): Msal.UserAgentApplication => {
    adTenantId = tenantId;
    adClientId = clientId;
    msalApp = new Msal.UserAgentApplication({
        auth: {
            clientId,
            authority: `https://login.microsoftonline.com/${tenantId}`,
            validateAuthority: true,
            redirectUri: window.location.origin,
            postLogoutRedirectUri: 'https://www.equinor.com',
            navigateToLoginRequestUrl: false
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: isIE()
        },
        system: {
            navigateFrameWait: 0,
            logger: new Msal.Logger(loggerCallback, {
                level: Msal.LogLevel.Error,
                piiLoggingEnabled: false,
                correlationId: '1234'
            })
        }
    });

    msalApp.handleRedirectCallback((error, response) => {
        if (error) {
            noAccess = true;

            if (error.message.indexOf("The signed in user '{EmailHidden}' is not assigned to a role for the application")) {
                authError = 'Not authorized for this application';
            } else {
                console.log(error);
                authError = error.message;
            }

            return;
        }

        if (response.account) {
            adAccount = response.account;
        }

        if (response.accessToken) {
            adAccessToken = response.accessToken;
        }
    });

    return msalApp;
};

export const initAuthentication = async (): Promise<Msal.UserAgentApplication> => {
    const publicConfig = await api.clientPublicConfig();

    if (publicConfig.clientId && publicConfig.tenantId) {
        return initMsal(publicConfig.clientId, publicConfig.tenantId);
    }
    return null;
};

export const requiresInteraction = (errorMessage): boolean => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return errorMessage.indexOf('consent_required') > -1 || errorMessage.indexOf('interaction_required') > -1 || errorMessage.indexOf('login_required') > -1;
};

export const acquireToken = async (): Promise<any> => {
    const request = createSignInRequest();

    return msalApp.acquireTokenSilent(request).catch((error) => {
        // Call acquireTokenRedirect in case of acquireTokenSilent failure
        // due to consent or interaction required ONLY
        if (requiresInteraction(error.errorCode)) {
            console.log('Acquiring new token');
            return msalApp.acquireTokenRedirect(request);
        } else {
            console.log('Unknown error - attempting to acquire new token');
            return msalApp.acquireTokenRedirect(request); // Testing redirecting in any case
        }
    });
};

export const ensureValidToken = async (): Promise<string> => {
    const token = await acquireToken();
    if (token) {
        adAccessToken = token.accessToken;
        return token.accessToken;
    }

    return null;
};

export const signIn = async (): Promise<any> => {
    if (noAccess) return;

    if (msalApp.getAccount() && !msalApp.isCallback(window.location.hash)) {
        console.log('Already signed in');
    } else {
        console.log('Signing in');
        redirecting = true;

        return msalApp.loginRedirect(createSignInRequest());
    }
};

export const signOut = async (): Promise<void> => {
    msalApp.logout();
    adAccessToken = null;
    adAccount = null;
    noAccess = false;
};

export const getAccount = async (): Promise<void> => {
    if (noAccess) return;

    adAccount = msalApp.getAccount();

    if (adAccount) {
        const tokenResponse = await acquireToken();

        if (tokenResponse) {
            adAccessToken = tokenResponse.accessToken;
        }
    }
};
