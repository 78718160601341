import { initAuthentication, signIn, adAccount, getAccount, redirecting } from './AuthUtils';
import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { StoreProvider } from 'easy-peasy';
import configureStore from './store/ConfigureStore';
import PortalStore from './store/PortalStore';
import App from './App';
import { unregister } from './serviceWorker';
import 'react-virtualized/styles.css'; // only needs to be imported once

declare global {
    interface Window {
        initialReduxState: any; // Needed to add this to make typescript build
    }
}

const originalStringify = JSON.stringify;

// msal blows up on serializing account info. This is a hack to get it to work
const msalHackyFilter = (key, value): any => {
    if (key === 'profile' && value && value.accountIdentifier) {
        return value.accountIdentifier;
    } else {
        return value;
    }
};

JSON.stringify = (value, filter, space): string => {
    if (!filter) filter = msalHackyFilter;
    return originalStringify(value, filter, space);
};

initAuthentication().then((msal) => {
    // Create browser history to use in the Redux store
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
    // @ts-ignore
    const history = createBrowserHistory({ basename: baseUrl });
    // Get the application-wide store instance, pre-populating with state from the server where available.
    const initialState = window.initialReduxState;
    const store = configureStore(history, initialState);

    const rootElement = document.getElementById('root');

    if (window === window.parent && window === window.top && !msal.isCallback(window.location.hash)) {
        signIn().then(() => {
            if (redirecting) {
                return ReactDOM.render(<div>Please stand by while we redirect you to the login</div>, rootElement);
            }

            getAccount().then(() => {
                const aStore = PortalStore(adAccount);
                return ReactDOM.render(
                    <StoreProvider store={aStore}>
                        <Provider store={store}>
                            <ConnectedRouter history={history}>
                                <App />
                            </ConnectedRouter>
                        </Provider>
                    </StoreProvider>,
                    rootElement
                );
            });
        });
    } else {
        signIn().then(() => {
            if (redirecting) {
                return ReactDOM.render(<div>Please stand by while we redirect you to the login</div>, rootElement);
            }

            const aStore = PortalStore(adAccount);
            return ReactDOM.render(
                <StoreProvider store={aStore}>
                    <Provider store={store}>
                        <ConnectedRouter history={history}>
                            <div>My little iframe div</div>
                        </ConnectedRouter>
                    </Provider>
                </StoreProvider>,
                rootElement
            );
        });
    }
});

unregister();
