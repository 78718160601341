import React from 'react';
import { Switch } from 'react-router';
import { RouteWithUnhandledExceptionBoundary as Route } from '../components/RouteWithUnhandledExceptionBoundary';
import EquipmentNumberAllocation from './allocation/EquipmentNumberAllocation';
import Home from './home/Home';
import NoMatchPage from './NoMatchPage';
import SieNumberSearch from './search/SieNumberSearch';
import adminPage from './admin/AdminPage';

const Routes = (): React.ReactElement => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/allocation" component={EquipmentNumberAllocation} />
            <Route exact path="/search" component={SieNumberSearch} />
            <Route exact path="/admin" component={adminPage} />
            <Route component={NoMatchPage} />
        </Switch>
    );
};

export default Routes;
