export const LogException = (error, stack) =>
    // @ts-ignore
    window.appInsights &&
    // @ts-ignore
    window.appInsights.trackException({
        // @ts-ignore
        error,
        properties: { stack }
    }) &&
    // @ts-ignore
    window.appInsights.flush();
