import React, { useEffect, useState } from 'react';
import { createFilter } from 'react-select';
import {
    Alert,
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Spinner,
    Table
} from 'reactstrap';
import { useMountEffect, useTitle } from '../../CustomHooks';
import ApiResponse from '../../models/ApiResponse';
import { SieObject } from '../../models/SieObject';
import {
    GetAllProjectFromRequest,
    getContractorCodes,
    getContractorCodesForSearch,
    getFunctionCodes,
    getFunctionCodesForSearch,
    getProjectPrefixesSearch,
    getProjects,
    getSapEquipmentNumbersForSelect,
    getSearchFacilities,
    getSieNumbers
} from '../../services/Api';
import { useStoreActions, useStoreState } from '../../store/PortalStore';
import SieNumberDetails from '../details/SieNumberDetails';
import SieNumberDetailsEdit from '../details/SieNumberDetailsEdit';
import NoAccess from '../NoAccess';
import { ExportExcel } from '../sie-components/Export';
import Select from '../sie-components/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faFileExcel } from '@fortawesome/free-solid-svg-icons'

const SieNumberSearch = (): React.ReactElement => {
    const [fetchingSieNumbers, setFetchingSieNumbers] = useState(false);
    const [totalSieObjectsFound, setTotalSieObjectsFound] = useState(0);
    const [error, setError] = useState(undefined);

    const [fetchingFacilities, setFetchingFacilities] = useState(false);
    const [facilities, setFacilities] = useState([]);
    const [facility, setFacility] = useState(null);

    const [sieObject, setSieObject] = useState(undefined);

    const [fetchingProjects, setFetchingProjects] = useState(false);
    const [projects, setProjects] = useState([]);

    const project = useStoreState((state) => state.sieObject.project);
    const setProject = useStoreActions((actions) => actions.sieObject.setProject);

    const [fetchingProjectPrefixes, setFetchingProjectPrefixes] = useState(false);
    const [projectPrefixes, setProjectPrefixes] = useState([]);
    const [projectPrefix, setProjectPrefix] = useState(undefined);

    const [fetchingFunctionCodes, setFetchingFunctionCodes] = useState(false);
    const [functionCodes, setFunctionCodes] = useState([]);
    const [functionCode, setFunctionCode] = useState(undefined);

    const [fetchingContractorCodes, setFetchingContractorCodes] = useState(false);
    const [contractorCodes, setContractorCodes] = useState([]);
    const [contractorCode, setContractorCode] = useState(null);
    const [conInput, setContrInput] = useState(null);

    const [fetchingSapEquipmentNumbers, setFetchingSapEquipmentNumbers] = useState(false);
    const [sapEquipmentNumbers, setSapEquipmentNumbers] = useState([]);
    const [sapEquipmentNumber, setSapEquipmentNumber] = useState([]);
    const [sapEquipmentInput, setSapEquipmentInput] = useState(null);

    const [searchInputValid, setSearchInputValid] = useState(false);

    const [numberOfPagesAvailable, setNumberOfPagesAvailable] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [showExcel, setShowExcel] = useState(false);

    const [sieEditAccess, setSieEditAccess] = useState(false);

    const [isAsc, setIsAsc] = useState(false);
    const [orderBy, setOrderBy] = useState(2);
    const [noEachPage, setNoEachPage] = useState(10);

    const [fetchingAll, setFetchingAll] = useState(false);
    const [allSieObjects, setAllSieObjects] = useState([]);

    const selected = useStoreState((state) => state.sieObject.selectedSieObject);
    const setSelected = useStoreActions((actions) => actions.sieObject.setSelectedSieObject);

    const sieObjects = useStoreState((state) => state.sieObject.sieObjects);
    const setSieObjects = useStoreActions((actions) => actions.sieObject.setSelectedSieObjects);


    const superUserAccess = useStoreState(state => state.user.sieFunctions.sieSuperUser);
    const sieReadAccess = useStoreState((state) => state.user.sieFunctions.sieRead);
    const sieEditSites = useStoreState(state => state.user.sieFunctions.sieEditSite);
    const sieEdit = useStoreState(state => state.user.sieFunctions.sieEdit);

    if (!sieReadAccess) {
        return (
            <NoAccess Module={'Number Search'} access={'SIE Read all plants (SIE portal)'} />
        );
    }

    useTitle('Number search');

    useEffect(() => {
        setSieEditAccess(false)

        if (superUserAccess) setSieEditAccess(true)

        if (sieEdit && sieEditSites?.length > 0 && !superUserAccess) {
            sieEditSites?.forEach(function (x) {
                if (x === facility?.value) {
                    setSieEditAccess(true)
                }
            })
        }
    }, [facility]);

    useMountEffect(() => {
        let isMounted = true;

        setFetchingFacilities(true);
        getSearchFacilities().then((result) => {
            if (!isMounted) return;
            if ((result as ApiResponse)?.error) {
                setError(result.error);
                setFetchingFacilities(false);
            } else {
                setFacilities(result as ApiResponse[]);
                setFetchingFacilities(false);
            }
        });

        setFetchingFunctionCodes(true);
        getFunctionCodesForSearch().then((result) => {
            if (!isMounted) return;
            if ((result as ApiResponse)?.error) {
                setError(result.error);
                setFetchingFunctionCodes(false);
            } else {
                setFunctionCodes(result as ApiResponse[]);
                setFetchingFunctionCodes(false);
            }
        });

        setFetchingProjects(true);
        GetAllProjectFromRequest().then((result) => {
            if (!isMounted) return;
            if ((result as ApiResponse)?.error) {
                setError(result.error);
                setFetchingProjects(false);
            } else {
                setProjects(result as ApiResponse[]);
                setFetchingProjects(false);
            }
        });

        setFetchingProjectPrefixes(true);
        getProjectPrefixesSearch(null).then((result) => {
            if (!isMounted) return;
            if ((result as ApiResponse)?.error) {
                setError(result.error);
                setFetchingProjectPrefixes(false);
            } else {
                setProjectPrefixes(result as ApiResponse[]);
                setFetchingProjectPrefixes(false);
            }
        });
        return () => {
            isMounted = false;
        };
    });

    const showDetails = (sieObjectDetail: SieObject) => {
        setSelected(sieObjectDetail);
    };

    const setObjects = (selectedSieObjects: Array<SieObject>) => {
        setSieObjects(selectedSieObjects);
    };


    useEffect(() => {
        setFetchingSapEquipmentNumbers(true);
        getSapEquipmentNumbersForSelect(sapEquipmentInput, null).then((result) => {
            if ((result as ApiResponse)?.error) {
                setError(result.error);
                setFetchingSapEquipmentNumbers(false);
            } else {
                setSapEquipmentNumbers(result as ApiResponse[]);
                setFetchingSapEquipmentNumbers(false);
            }
        });
    }, [sapEquipmentInput]);

    useEffect(() => {
        if (facility?.value) {
            setFunctionCodes([]);
            setProjectPrefixes([]);
            setProjects([]);
            setFunctionCode(null);
            setProjectPrefix(null);
            setProject(null);
            setFetchingFunctionCodes(true);
            setFetchingProjectPrefixes(true);
            setFetchingProjects(true);

            getFunctionCodes(facility?.value).then(async (result) => {
                if ((result as ApiResponse)?.error) {
                    setError(result.error);
                    setFetchingFunctionCodes(false);
                } else {
                    setFunctionCodes(result as ApiResponse[]);
                    setFetchingFunctionCodes(false);
                }
            });

            getProjectPrefixesSearch(facility?.value).then((result) => {
                if ((result as ApiResponse)?.error) {
                    setError(result.error);
                    setFetchingProjectPrefixes(false);
                } else {
                    setProjectPrefixes(result as ApiResponse[]);
                    setFetchingProjectPrefixes(false);
                }
            });

            getProjects(facility?.value).then((result) => {
                if ((result as ApiResponse)?.error) {
                    setError('error fetching projects');
                    setFetchingProjects(false);
                } else {
                    setProjects(result as ApiResponse[]);
                    setFetchingProjects(false);
                }
            });
        } else {
            setFetchingProjects(true);
            GetAllProjectFromRequest().then((result) => {
                if ((result as ApiResponse)?.error) {
                    setError(result.error);
                    setFetchingProjects(false);
                } else {
                    setProjects(result as ApiResponse[]);
                    setFetchingProjects(false);
                    setProject(null);
                    setProjectPrefix(null);
                }
            });
            setFetchingFunctionCodes(true);
            getFunctionCodesForSearch().then((result) => {
                if ((result as ApiResponse)?.error) {
                    setError(result.error);
                    setFetchingFunctionCodes(false);
                } else {
                    setFunctionCodes(result as ApiResponse[]);
                    setFetchingFunctionCodes(false);
                }
            });

            setFetchingProjectPrefixes(true);
            getProjectPrefixesSearch(null).then((result) => {
                if ((result as ApiResponse)?.error) {
                    setError(result.error);
                    setFetchingProjectPrefixes(false);
                } else {
                    setProjectPrefixes(result as ApiResponse[]);
                    setFetchingProjectPrefixes(false);
                }
            });
        }

    }, [facility]);

    useEffect(() => {
        setFetchingContractorCodes(true);
        getContractorCodesForSearch(conInput, facility?.value, null).then((result) => {
            if ((result as ApiResponse)?.error) {
                setError(result.error);
                setFetchingContractorCodes(false);
            } else {
                setContractorCodes(result as ApiResponse[]);
                setFetchingContractorCodes(false);
            }
        });

        if (facility?.value) {
            setFetchingContractorCodes(true);
            getContractorCodes(facility.value).then((result) => {
                if ((result as ApiResponse)?.error) {
                    setError(result.error);
                    setFetchingContractorCodes(false);
                } else {
                    setContractorCodes(result as ApiResponse[]);
                    setFetchingContractorCodes(false);
                }
            });
            return;
        }
    }, [conInput, facility]);

    useEffect(() => {
        setSearchInputValid(
            facility?.value?.length > 0 ||
            projectPrefix?.value?.length > 0 ||
            project?.name?.length > 0 ||
            sieObject?.length > 0 ||
            sapEquipmentNumber?.length > 0 ||
            contractorCode?.value?.length > 0 ||
            functionCode?.value?.length > 0
        );
    }, [sieObject, project, facility, project, projectPrefix, contractorCode, functionCode, sapEquipmentNumber]);

    const Search = (): void => {
        if (currentPage === 1) {
            fetchNumbers(1);
        } else {
            setCurrentPage(1);
        }
    };

    const ClearFilter = (): void => {

        setProjectPrefix(null);
        setFetchingProjectPrefixes(false);

        setContractorCode(null);
        setFetchingContractorCodes(false);

        setFacility(null);

        setProject(null);
        setFetchingProjects(false);

        setSieObject('');
        setFetchingSieNumbers(false);

        setFunctionCode(null);
        setFetchingFunctionCodes(false);
        setSapEquipmentNumber([]);
    };

    const fetchNumbers = (page: number) => {
        if (!searchInputValid) return;
        setError(undefined);
        if (!fetchingSieNumbers && searchInputValid) {
            setFetchingSieNumbers(true);
            getSieNumbers({
                facility: facility?.value,
                sieNumber: sieObject,
                project: project?.value,
                projectPrefix: projectPrefix?.value,
                functionType: functionCode?.value,
                contractorCode: contractorCode?.value,
                SapEquipmentNumbers: sapEquipmentNumber && sapEquipmentNumber.length > 0 ? sapEquipmentNumber : null,
                sapPlantNo: null,
                status: null,
                createdByTieApplication: null,
                createdByProject: null,
                createdByFacility: null,
                createdByContrCode: null,
                page: page,
                noEachPage: noEachPage,
                orderBy: orderBy,
                isAsc: isAsc
            })
                .then(async (result) => {
                    if ((result as ApiResponse)?.error) {
                        setError(result.error);
                        setFetchingSieNumbers(false);
                    } else {
                        setObjects(result.sieObjects);
                        setTotalSieObjectsFound(result.totalObjects);
                        setNumberOfPagesAvailable(result.totalPages);
                        setFetchingSieNumbers(false);
                    }
                })
                .catch(() => {
                    setError('Error while fetching allocated numbers');
                    setFetchingSieNumbers(false);
                });
        }
    };

    useEffect(() => {
        if (currentPage < 1 || currentPage > numberOfPagesAvailable) {
            return;
        }
        fetchNumbers(currentPage);
    }, [currentPage]);

    const converted = sieObjects
        ? sieObjects.map((x) => {
            return {
                Facility: x?.facility,
                'SIE Number': x.sieNumber,
                Project: x?.project,
                'SIE Project Prefix': x?.projectPrefix,
                'Function Type': x?.functionType,
                'Contractor Code': x?.contractorCode,
                Created: x?.created,
                'Sap Equipment Number': x?.sapEquipmentNo?.name,
                'Sap Plant Number': x?.sapPlantNo,
                Status: x?.status,
                'Created By Tie Application': x?.createdByTieApplication,
                'Created By Project': x?.createdByProject,
                'Created By Facility': x?.createdByFacility,
                'Created By Contractor Code': x?.createdByContrCode
            };
        })
        : null;
    const allConverted = allSieObjects
        ? allSieObjects.map((x) => {
            return {
                Facility: x?.facility,
                'SIE Number': x.sieNumber,
                Project: x?.project,
                'SIE Project Prefix': x?.projectPrefix,
                'Function Type': x?.functionType,
                'Contractor Code': x?.contractorCode,
                Created: x?.created,
                'Sap Equipment Number': x?.sapEquipmentNo?.name,
                'Sap Plant Number': x?.sapPlantNo,
                Status: x?.status,
                'Created By Tie Application': x?.createdByTieApplication,
                'Created By Project': x?.createdByProject,
                'Created By Facility': x?.createdByFacility,
                'Created By Contractor Code': x?.createdByContrCode
            };
        })
        : null;

    const sort = (value: number) => {
        if (value === orderBy && isAsc === false) {
            setIsAsc(true);
        } else setIsAsc(false);
        setOrderBy(value);
    }
    useEffect(() => {
        Search();
    }, [orderBy, isAsc, noEachPage])

    useEffect(() => {
        if (showExcel) {
            setFetchingAll(true);
            getSieNumbers({
                facility: facility?.value,
                sieNumber: sieObject,
                project: project?.value,
                projectPrefix: projectPrefix?.value,
                functionType: functionCode?.value,
                contractorCode: contractorCode?.value,
                SapEquipmentNumbers: sapEquipmentNumber && sapEquipmentNumber.length > 0 ? sapEquipmentNumber : null,
                sapPlantNo: null,
                status: null,
                createdByTieApplication: null,
                createdByProject: null,
                createdByFacility: null,
                createdByContrCode: null,
                page: 1,
                noEachPage: 99999,
                orderBy: 0,
                isAsc: false
            })
                .then(async (result) => {
                    if ((result as ApiResponse)?.error) {
                        setError(result.error);
                        setFetchingSieNumbers(false);
                    } else {
                        setAllSieObjects(result.sieObjects);
                        setFetchingSieNumbers(false);
                    }
                })
                .finally(() => setFetchingAll(false))
                .catch(() => {
                    setError('Error while fetching allocated numbers');
                    setFetchingSieNumbers(false);
                });
        }
    }, [showExcel])

    return (
        <>
            <Card>
                <CardHeader>
                    <h3 className="float-left">Subsea item equipment number search</h3>
                    <div className="float-right" >
                        <Button color="primary" onClick={ClearFilter}>
                            &#x21bb; Reset filter
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <Form>
                        <FormGroup row>
                            <Label for="facility" sm={2}>
                                Facility
                            </Label>
                            <Col sm={10}>
                                <Select
                                    id="facility"
                                    isLoading={fetchingFacilities}
                                    isMulti={false}
                                    formatOptionLabel={(option): string => option.name}
                                    getOptionValue={(option): string => option.value}
                                    getOptionLabel={(option): string => option.name}
                                    options={facilities.map((f) => {
                                        return { name: `${f.name} | ${f.description}`, value: f.name };
                                    })}
                                    value={facility}
                                    onChange={(value) => setFacility(value)}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="sieNumber" sm={2}>
                                SIE number
                            </Label>
                            <Col sm={10}>
                                <Input
                                    id="sieNumber"
                                    type="text"
                                    value={sieObject ?? ''}
                                    onChange={(e) => {
                                        setSieObject(e.target.value);
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="project" sm={2}>
                                Project
                            </Label>
                            <Col sm={10}>
                                <Select
                                    id="project"
                                    isLoading={fetchingProjects}
                                    isMulti={false}
                                    formatOptionLabel={(option): string => option.name}
                                    getOptionValue={(option): string => option.value}
                                    getOptionLabel={(option): string => option.name}
                                    options={projects.map((p) => {
                                        return { name: `${p.name} | ${p.description}`, value: p.name };
                                    })}
                                    value={project}
                                    onChange={(value) => setProject(value)}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="projectPrefix" sm={2}>
                                SIE Project prefix
                            </Label>
                            <Col sm={10}>
                                <Select
                                    id="projectPrefix"
                                    isLoading={fetchingProjectPrefixes}
                                    isMulti={false}
                                    formatOptionLabel={(option): string => option.name}
                                    getOptionValue={(option): string => option.value}
                                    getOptionLabel={(option): string => option.name}
                                    options={projectPrefixes.map((pp) => {
                                        return { name: `${pp.name} | ${pp.description}`, value: pp.name };
                                    })}
                                    value={projectPrefix}
                                    onChange={(value) => setProjectPrefix(value)}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="functionCode" sm={2}>
                                Function type
                            </Label>
                            <Col sm={10}>
                                <Select
                                    id="functionCode"
                                    isLoading={fetchingFunctionCodes}
                                    isMulti={false}
                                    formatOptionLabel={(option): string => option.name}
                                    getOptionValue={(option): string => option.value}
                                    getOptionLabel={(option): string => option.name}
                                    options={functionCodes.map((fc) => {
                                        return { name: `${fc.name} | ${fc.description}`, value: fc.name };
                                    })}
                                    value={functionCode}
                                    onChange={(value) => setFunctionCode(value)}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="contractorCode" sm={2}>
                                Contractor code
                            </Label>
                            <Col sm={10}>
                                <Select
                                    id="contractorCode"
                                    captureMenuScroll={false}
                                    cacheOptions
                                    ignoreAccents={false}
                                    isLoading={fetchingContractorCodes}
                                    isMulti={false}
                                    Options={{ onmouseover: false, onmousemove: false }}
                                    filterOption={createFilter({ ignoreAccents: false })}
                                    maxMenuHeight={220}
                                    menuPlacement="auto"
                                    formatOptionLabel={(option): string => option.name}
                                    getOptionValue={(option): string => option.value}
                                    getOptionLabel={(option): string => option.name}
                                    options={contractorCodes.map((n) => {
                                        return { name: `${n.name} | ${n.description}`, value: n.name, onmouseover: false, onmousemove: false };
                                    })}
                                    value={contractorCode}
                                    onChange={(value) => setContractorCode(value)}
                                    onInputChange={(inputValue) => setContrInput(inputValue)}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="sapEquipment" sm={2}>
                                SAP Equipment No
                            </Label>
                            <Col sm={10}>
                                <Select
                                    id="sapEquipment"
                                    isLoading={fetchingSapEquipmentNumbers}
                                    isMulti={true}
                                    formatOptionLabel={(option): string => option.name}
                                    getOptionValue={(option): string => option.value}
                                    getOptionLabel={(option): string => option.name}
                                    options={sapEquipmentNumbers?.map((n) => {
                                        return { name: n.name, value: n.name };
                                    })}
                                    value={sapEquipmentNumber?.map((s) => {
                                        return { name: s, value: s };
                                    })}
                                    onChange={(value) => setSapEquipmentNumber(value?.map((v) => v.value))}
                                    onInputChange={(inputValue) => setSapEquipmentInput(inputValue)}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                        <Row>
                            <Col sm={{ size: 10, offset: 2 }}>
                                <Button color="primary" onClick={Search} disabled={fetchingSieNumbers || !searchInputValid}>
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    {error && (
                        <Row style={{ marginTop: '2rem' }}>
                            <Col sm={12}>
                                <Alert color="danger">Error: {error}</Alert>
                            </Col>
                        </Row>
                    )}
                </CardBody>
            </Card>
            {sieObjects && (
                <Card style={{ marginTop: '2em' }}>
                    <CardHeader>
                        <h3 className="float-left">Allocated numbers</h3>
                        {totalSieObjectsFound > 0 && (
                            <div className="float-right" role="button">
                                <FontAwesomeIcon icon={faFileExcel} size='2x' onClick={() => setShowExcel(!showExcel)} />
                            </div>)}
                    </CardHeader>
                    {totalSieObjectsFound > 0 && (
                        <div className="float-left">
                            <Col sm={10}>
                                <i>Showing {sieObjects.length } of total {totalSieObjectsFound} allocated numbers</i>
                            </Col>
                        </div>)}
                    <CardBody>
                        {totalSieObjectsFound === 0 && <i>No allocated SIE numbers found</i>}
                        {totalSieObjectsFound > 0 && (
                            <>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <a role="button" onClick={() => sort(1)}>Facility</a>
                                                {orderBy === 1 && (isAsc ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />)}
                                            </th>
                                            <th>
                                                <a role="button" onClick={() => sort(2)}>SIE Number</a>
                                                {(orderBy === 2 || orderBy === 0) && (isAsc ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />)}
                                            </th>
                                            <th>
                                                <a role="button" onClick={() => sort(3)}>Project</a>
                                                {orderBy === 3 && (isAsc ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />)}
                                            </th>
                                            <th>
                                                <a role="button" onClick={() => sort(4)}>SIE Project Prefix</a>
                                                {orderBy === 4 && (isAsc ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />)}
                                            </th>
                                            <th>
                                                <a role="button" onClick={() => sort(5)}>Function Type</a>
                                                {orderBy === 5 && (isAsc ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />)}
                                            </th>
                                            <th>
                                                <a role="button" onClick={() => sort(6)}>Contractor Code</a>
                                                {orderBy === 6 && (isAsc ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />)}
                                            </th>
                                            <th>
                                                <a role="button" onClick={() => sort(7)}>SAP Equipment No</a>
                                                {orderBy === 7 && (isAsc ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />)}
                                            </th>
                                            <th>
                                                <a role="button" onClick={() => sort(8)}>Status</a>
                                                {orderBy === 8 && (isAsc ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />)}
                                            </th>
                                            <th>
                                                <a role="button" onClick={() => sort(9)}>Created</a>
                                                {orderBy === 9 && (isAsc ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />)}

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sieObjects.map((n) => (
                                            <tr key={n.sieNumber} onClick={() => showDetails(n)} style={{ cursor: 'pointer' }}>
                                                <td>{n.facility}</td>
                                                <td>
                                                    <Badge color="secondary">{n.sieNumber.toUpperCase()}</Badge>
                                                </td>
                                                <td>{n.project}</td>
                                                <td>{n.projectPrefix}</td>
                                                <td>{n.functionType.toUpperCase()}</td>
                                                <td>{n.contractorCode}</td>
                                                <td>{n.sapEquipmentNo?.name}</td>
                                                <td>{n.status}</td>
                                                <td>{n.created}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {numberOfPagesAvailable > 0 && (
                                    <Row>
                                        <Col md={2}>
                                            <Row className="ml-2">
                                                <select name='take' id='take' onChange={(e) => setNoEachPage(parseInt(e.target.value))} >
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                                <p>Entries per page</p>
                                            </Row>
                                        </Col>
                                        <Col sm={8}>
                                            <Pagination style={{ justifyContent: 'center' }}>
                                                <PaginationItem disabled={currentPage <= 1}>
                                                    <PaginationLink first onClick={() => setCurrentPage(1)} />
                                                </PaginationItem>
                                                <PaginationItem disabled={currentPage <= 1}>
                                                    <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)} />
                                                </PaginationItem>
                                                {[...Array(numberOfPagesAvailable)]
                                                    .map((_, i) => i + 1)
                                                    .filter((i) => i > (currentPage - 5) && i < (currentPage + 5))
                                                    .map((i) => (
                                                        <PaginationItem key={i} active={currentPage === i}>
                                                            <PaginationLink onClick={() => setCurrentPage(i)}>{i}</PaginationLink>
                                                        </PaginationItem>
                                                    ))}
                                                <PaginationItem disabled={currentPage >= numberOfPagesAvailable - 1}>
                                                    <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)} />
                                                </PaginationItem>
                                                <PaginationItem disabled={currentPage >= numberOfPagesAvailable - 1}>
                                                    <PaginationLink last onClick={() => setCurrentPage(numberOfPagesAvailable)} />
                                                </PaginationItem>
                                            </Pagination>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                    </CardBody>
                </Card>
            )}
            {sieEditAccess && <SieNumberDetailsEdit sieObject={selected} visible={selected !== null} setVisible={() => setSelected(null)} />}
            {!sieEditAccess && <SieNumberDetails sieObject={selected} visible={selected !== null} setVisible={() => setSelected(null)} />}
            <Modal isOpen={showExcel} size="lg">
                <ModalHeader>How many SIE Numbers do you want to include in the spreadsheet?</ModalHeader>
                <ModalBody>
                    {totalSieObjectsFound > noEachPage &&
                        <div className="float-left">
                            <i> Get only the numbers currently on the page ({sieObjects.length < noEachPage ? sieObjects.length : noEachPage })</i>
                            <ExportExcel data={converted}
                                filename={'SieNumbers_from_search_' + new Date(Date.now()).toLocaleDateString('en-GB').toString()} />
                        </div>
                    }
                    <div className="float-right">
                        <i> Get all numbers from current search ({totalSieObjectsFound})</i>
                        {fetchingAll ? <Spinner size="sm" /> : <ExportExcel data={allConverted} disabled={fetchingAll}
                            filename={'SieNumbers_from_search_' + new Date(Date.now()).toLocaleDateString('en-GB').toString()} />}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setShowExcel(false)}>Exit</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default SieNumberSearch;
