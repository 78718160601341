import React, { useEffect, useState } from 'react';
import {
    Alert,
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Table
} from 'reactstrap';
import { useMountEffect, useTitle } from '../../CustomHooks';
import ApiResponse from '../../models/ApiResponse';
import { getFunctionCodes, getProjectPrefixesAllocation, getProjects, getContractorCodes, getFacilityByUser, previewSieNumbers, allocateSieNumbers, rangePreview } from '../../services/Api';
import { createError, formatError, RenderError } from '../sie-components/Errors';
import Select from '../sie-components/Select';
import { useStoreState } from '../../store/PortalStore';
import NoAccess from '../NoAccess';
import { ExportExcel } from '../sie-components/Export';
import _ from 'lodash';
import { Error } from '../../models/Error';

const excessiveQuantityLimit = 100;

const EquipmentNumberAllocation = (): React.ReactElement => {
    const [fetchingFacilities, setFetchingFacilities] = useState(false);
    const [facilities, setFacilities] = useState([]);
    const [facility, setFacility] = useState(undefined);

    const [fetchingProjects, setFetchingProjects] = useState(false);
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState(undefined);

    const [fetchingProjectPrefixes, setFetchingProjectPrefixes] = useState(false);
    const [projectPrefixes, setProjectPrefixes] = useState([]);
    const [projectPrefix, setProjectPrefix] = useState(undefined);

    const [fetchingFunctionCodes, setFetchingFunctionCodes] = useState(false);
    const [functionCodes, setFunctionCodes] = useState([]);
    const [functionCode, setFunctionCode] = useState(undefined);

    const [fetchingContractorCodes, setFetchingContractorCodes] = useState(false);
    const [contractorCodes, setContractorCodes] = useState([]);
    const [contractorCode, setContractorCode] = useState(undefined);

    const [quantity, setQuantity] = useState(1);
    const [startingNumber, setStartingNumber] = useState(1);

    const [inputValid, setInputValid] = useState(false);
    const [allocating, setAllocating] = useState(false);
    const [allocationResult, setAllocationResult] = useState(undefined);
    const [mappedAllocatedObjects, setMappedAllocatedObjects] = useState(undefined);
    const [allocationErrors, setAllocationErrors] = useState<Error>(undefined);

    const [error, setError] = useState(undefined);
    const [showExcessiveQuantityConfirmation, setShowExcessiveQuantityConfirmation] = useState(false);

    const [preview, setPreview] = useState(undefined);
    const [previewError, setPreviewError] = useState(undefined);

    const [nonSequential, setNonSequential] = useState('')
    const [problematicNumbers, setProblematicNumbers] = useState([]);
    const [allocationOption, setAllocationOption] = useState(0);

    const [availabilityChecking, setAvailabilityChecking] = useState(false)
    const [availabilityResponse, setAvailabilityResponse] = useState('');
    const [availabilityError, setAvailabilityError] = useState('');

    const [ranges, setRanges] = useState(undefined);
    const [range, setRange] = useState(undefined);
    const [rangeError, setRangeError] = useState('');
    const [fetchingRanges, setFetchingRanges] = useState(false);

    const [LowerLimit, setLowerLimit] = useState(0)
    const [UpperLimit, setUpperLimit] = useState(0)

    const [sequentialOverflow, setSequentialOverflow] = useState(false);
    const [suggestedOverflow, setSuggestedOverflow] = useState(false);


    useTitle('Number allocation');

    const access = useStoreState((state) => state.user.sieFunctions.sieAllocate);
    const user = useStoreState((state) => state.user.name);

    if (!access) {
        return (
            <NoAccess Module={'Number allocation'} access={'SIE Create (plant specific) or SIE administrator'} />
        );
    }

    useMountEffect(() => {
        let isMounted = true;

        setFetchingFacilities(true);
        getFacilityByUser().then(async (result) => {
            if (!isMounted) return;
            if ((result as ApiResponse)?.error) {
                setError(createError('allocation', 'Error: ', result?.error));
                setFetchingFacilities(false);
            } else {
                setFacilities(result as ApiResponse[]);
                setFetchingFacilities(false);
            }
        });
        return () => {
            isMounted = false;
        };
    });

    useEffect(() => {
        if (!facility?.value) return;

        setFetchingProjects(true);
        setProject(null);

        getProjects(facility.value).then(async (result) => {
            if ((result as ApiResponse)?.error) {
                setError(createError('allocation', 'Error: ', 'Could not fetch projects'));
                setFetchingProjects(false);
            } else {
                setProjects(result as ApiResponse[]);
                setFetchingProjects(false);
            }
        });
        setFetchingFunctionCodes(true);
        getFunctionCodes(facility.value).then(async (result) => {
            if ((result as ApiResponse)?.error) {
                setError(createError('allocation', 'Error: ', result?.error));
                setFetchingFunctionCodes(false);
            } else {
                setFunctionCodes(result as ApiResponse[]);
                setFetchingFunctionCodes(false);
            }
        });
    }, [facility]);

    useEffect(() => {
        if (!facility?.value) return;

        setFetchingContractorCodes(true);
        setContractorCode(null);

        getContractorCodes(facility.value).then(async (result) => {
            if ((result as ApiResponse)?.error) {
                setError(createError('allocation', 'Error: ', result.error));
                setFetchingContractorCodes(false);
            } else {
                setContractorCodes(result as ApiResponse[]);
                setFetchingContractorCodes(false);
            }
        });
    }, [facility]);

    useEffect(() => {
        if (!facility?.value) return;
        setPreview(undefined);
        setFetchingProjectPrefixes(true);
        setProjectPrefix(null);

        getProjectPrefixesAllocation(facility.value).then(async (result) => {
            if ((result as ApiResponse)?.error) {
                setError(createError('allocation', 'Error: ', result.error));
                setFetchingProjectPrefixes(false);
            } else {
                setProjectPrefixes(result as ApiResponse[]);
                setFetchingProjectPrefixes(false);
            }
        });
    }, [facility]);

    useEffect(() => {
        setInputValid(quantity > 0 && projectPrefix?.value?.length > 0 && functionCode?.value?.length > 0 && facility && project);
    }, [projectPrefix, functionCode, quantity, facility, project]);

    const allocate = () => {
        if (quantity >= excessiveQuantityLimit) {
            setShowExcessiveQuantityConfirmation(true);
            return;
        }
        doAllocation();
    };

    const continueAllocation = (confirmed: boolean): void => {
        setShowExcessiveQuantityConfirmation(false);
        if (confirmed) doAllocation();
    };

    const availabilityCheck = () => {
        setAvailabilityChecking(true);
        previewSieNumbers({
            facility: facility?.value,
            project: project?.value,
            sieProjectPrefix: projectPrefix?.value,
            functionType: functionCode?.value,
            contrCode: contractorCode?.value,
            sieAllocationRequestMetadata: {
                sieAllocationRequestType: allocationOption === 3 ? 2 : allocationOption,
                User: user,
                quantity: allocationOption === 2 ? preview?.length : quantity,
                startSequenceNo: allocationOption === 1 ? startingNumber : null,
                sequenceNoList: allocationOption === 2 ? preview : allocationOption === 3 ? preview : null
            }
        }).then((result) => {
            if ((result as ApiResponse)?.error) {
                setError(result.error);
                setAvailabilityChecking(false);
            } else {
                result.errorMessages?.length !== 0 ?
                    (setAvailabilityError(result?.errorMessages?.join(' ')), setAvailabilityResponse(undefined))
                    : (setAvailabilityResponse('These numbers are available'), setAvailabilityError(undefined))
                setAvailabilityChecking(false);
            }
        })
    }

    useEffect(() => {
        setAvailabilityResponse(undefined);
        setAvailabilityError(undefined);
    }, [preview])

    const doAllocation = (): void => {
        setAllocating(true);
        setError(undefined);
        setAllocationErrors(undefined);

        allocateSieNumbers({
            facility: facility?.value,
            project: project?.value,
            sieProjectPrefix: projectPrefix?.value,
            functionType: functionCode?.value,
            contrCode: contractorCode?.value,
            createdByTieApplication: 'SIESOLUTION',
            sieAllocationRequestMetadata: {
                sieAllocationRequestType: allocationOption === 3 ? 2 : allocationOption,
                User: user,
                quantity: quantity,
                startSequenceNo: allocationOption === 1 ? startingNumber ?? 0 : null,
                SequenceNoList: allocationOption === 2 ? preview : allocationOption === 3 ? preview : null
            }
        })
            .then((result) => {
                if (!(result as ApiResponse)?.error) {
                    if (result.valid) {
                        setAllocationResult(result);
                        setAllocationErrors(undefined);
                    } else {
                        setAllocationResult(undefined);
                        setAllocationErrors(createError('allocation', 'Error: ', result.errorMessages));
                    }
                } else {
                    setError(createError('allocation', 'Error: ', `Something went wrong. Please contact support and provide the following error: ${JSON.stringify(result.error)}`));
                }
                setAllocating(false);
            })
            .catch((e) => {
                setError(createError('allocation', 'Error: ', `Error allocating numbers: ${formatError(e)}`));
            });
    };

    const allocated = allocationResult
        ? allocationResult?.allocatedSieObjects?.map((x) => {
            if (x === null || x === undefined) return null;
            return {
                Facility: x.facility.name,
                'SIE Number': x.sieNumber,
                Project: x.sieProject.name,
                'Contractor Code': x.contrCode,
                Created: new Date(Date.parse(x.created)).toLocaleString('en-GB'),
                'Sap Equipment Number': x.sapEquipmentNo,
                'Sap Plant Number': x.sapPlantNo,
                Status: x.sieStatus,
                'Created By Tie Application': x.createdBy_TieApplication,
                'Created By Project': x.createdBy_SieProject.name,
                'Created By Facility': x.createdBy_Facility.name,
                'Created By Contractor Code': x.createdBy_ContrCode
            };
        })
        : null;

    const RemoveAllocatedResult = (): void => {
        setAllocationResult(null);
    };

    const RemovePreviewResult = (): void => {
        setPreview([])
    };

    const ClearFilter = (): void => {
        setProjectPrefix(null);
        setProjectPrefixes([]);
        setContractorCode(null);
        setContractorCodes([]);
        setFacility(null);
        setProjects([]);
        setProject(null);
        setFunctionCodes([]);
        setFunctionCode(null);
    }

    useEffect(() => {
        setPreviewError('');
        setProblematicNumbers([]);
        setPreview([]);
        setRanges(undefined)
        const uncheckedNumbers = [];
        if (!project) {
            setPreviewError('Please select a project');
            return;
        }
        if (!projectPrefix) {
            setPreviewError('Please select a project prefix');
            return;
        }
        if (!functionCode) {
            setPreviewError('Please select a function code');
            return;
        }
        if (allocationOption === 0) {
            setPreviewError('Preview not available for simple allocation');
            return;
        }
        if (allocationOption === 1) {

            if (!startingNumber) {
                setPreviewError('Please select a start number');
                return;
            }
            const startNumber = startingNumber
            for (let i = 0; i < quantity; i++) {
                const x = startNumber + i;
                uncheckedNumbers.push(x);
            }
            setPreview(uncheckedNumbers)
        }
        if (allocationOption === 2) {

            if (!nonSequential) {
                setPreviewError('Please write one or more numbers');
                return;
            }
            const onlyNumber = /^\d+$/;
            const arr = nonSequential.split(',');

            const trimmedArr = arr.map(v => v.trim())
            setQuantity(trimmedArr.length);

            trimmedArr.forEach(number => {
                if (!onlyNumber.test(number)) {
                    setProblematicNumbers(pn => [...pn, number])
                } else if (number.length > 4) {
                    setProblematicNumbers(pn => [...pn, number])
                } else {
                    uncheckedNumbers.push(number);
                }
            })
            setPreview(uncheckedNumbers);
        }

        if (allocationOption === 3) {
            if (typeof range !== 'undefined' && range?.length !== 0) {
                const genesisNumber = ranges?.availableRanges[range]?.startingSequenceNumber;
                const topNumber = ranges?.availableRanges[range]?.endingSequenceNumber;
                for (let i = genesisNumber; i <= topNumber; i++) {
                    uncheckedNumbers.push(i);
                }
                setPreview(uncheckedNumbers);
            }
        }
    }, [functionCode, projectPrefix, quantity, allocationOption, nonSequential, startingNumber, range, project])

    const fetchRanges = () => {
        setRanges(undefined);
        setRangeError(undefined);
        setFetchingRanges(true);

        if (UpperLimit <= LowerLimit) {
            setRangeError('\'Upper limit\' has to be higher than \'Lower limit\'');
            setFetchingRanges(false);
            return;
        }

        if ((UpperLimit - LowerLimit) < quantity) {
            setRangeError('Range insufficient for the quantity to be assigned');
            setFetchingRanges(false);
            return;
        }

        if (!quantity) {
            setRangeError('Please set a quantity to be assigned');
            setFetchingRanges(false);
            return;
        }

        rangePreview({
            facility: facility?.value,
            project: project?.value,
            sieProjectPrefix: projectPrefix?.value,
            functionType: functionCode?.value,
            contrCode: contractorCode?.value,
            sieAllocationRequestMetadata: {
                sieAllocationRequestType: 5,
                User: user,
                quantity: quantity,
                lowerLimit: LowerLimit,
                UpperLimit: UpperLimit
            }
        }).then((result) => {
            result?.totalAvailable === 0 ?
                (setRangeError('No ranges found'))
                : (setRanges(result), setRangeError(undefined))
            setFetchingRanges(false);
        })
    }

    useEffect(() => {
        setRanges(undefined);
    }, [facility, project, functionCode, projectPrefix])

    function numberHandler(event) {
        const num = parseInt(event.target.value)
        if (isNaN(num)) return undefined
        if (num > 9999) return 9999
        else if (num < 1) return 1
        else return num
    }

    function quantityHandler(event) {
        setQuantity(numberHandler(event))
    }

    function upperHandler(event) {
        setUpperLimit(numberHandler(event))
    }

    function lowerHandler(event) {
        setLowerLimit(numberHandler(event))
    }

    function startNumberHandler(event) {
        setStartingNumber(numberHandler(event))
    }

    useEffect(() => {
        if (startingNumber + quantity >= 10000 && allocationOption === 1) setSequentialOverflow(true);
        else setSequentialOverflow(false);
    }, [startingNumber, quantity])

    useEffect(() => {
        if (UpperLimit - LowerLimit >= 1000 && allocationOption === 2) setSuggestedOverflow(true);
        else setSuggestedOverflow(false);
    }, [LowerLimit, UpperLimit])

    useEffect(() => {
        setMappedAllocatedObjects(allocationResult
            ? allocationResult?.allocatedSieObjects?.map((x) => {
                if (x === null || x === undefined) return null;
                return {
                    facility: x?.facility?.name,
                    sieNumber: x.sieNumber,
                    project: x?.sieProject?.name,
                    projectPrefix: x?.sieSequence?.projectPrefix?.name,
                    functionCode: x?.sieSequence?.functionType?.name,
                    contrCode: x.contrCode,
                    created: x.created,
                    sapEquipmentNumber: x.sapEquipmentNo,
                    sapPlantNumber: x.sapPlantNo,
                    status: x.sieStatus
                };
            })
            : null)
    }, [allocationResult])

    return (
        <>
            <Card>
                <CardHeader>
                    <h3 className="float-left">Subsea item equipment number allocation</h3>
                    <div>
                        <Button className="float-right" color="primary" onClick={ClearFilter}>
                            &#x21bb; Reset filter
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <Form>
                        <FormGroup row>
                            <Label for="facility" sm={2}>
                                Facility
                            </Label>
                            <Col sm={10}>
                                <Select
                                    id="facility"
                                    isLoading={fetchingFacilities}
                                    isMulti={false}
                                    formatOptionLabel={(option): string => option.name}
                                    getOptionValue={(option): string => option.value}
                                    getOptionLabel={(option): string => option.name}
                                    options={facilities.map((f) => {
                                        return { name: `${f.name} | ${f.description}`, value: f.name };
                                    })}
                                    value={facility}
                                    onChange={(value) => setFacility(value)}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="project" sm={2}>
                                Project
                            </Label>
                            <Col sm={10}>
                                <Select
                                    id="project"
                                    isLoading={fetchingProjects}
                                    isMulti={false}
                                    formatOptionLabel={(option): string => option.name}
                                    getOptionValue={(option): string => option.value}
                                    getOptionLabel={(option): string => option.name}
                                    options={projects.map((p) => {
                                        return { name: `${p.name} | ${p.description}`, value: p.name };
                                    })}
                                    value={project}
                                    onChange={(value) => setProject(value)}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="projectPrefix" sm={2}>
                                SIE project prefix
                            </Label>
                            <Col sm={10}>
                                <Select
                                    id="projectPrefix"
                                    isLoading={fetchingProjectPrefixes}
                                    isMulti={false}
                                    formatOptionLabel={(option): string => option.name}
                                    getOptionValue={(option): string => option.value}
                                    getOptionLabel={(option): string => option.name}
                                    options={projectPrefixes.map((pp) => {
                                        return { name: `${pp.name}`, value: pp.name };
                                    })}
                                    value={projectPrefix}
                                    onChange={(value) => setProjectPrefix(value)}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label htmlFor="contractorCode" sm={2}>
                                Contractor code
                            </Label>
                            <Col sm={10}>
                                <Select
                                    id="contractorCode"
                                    isLoading={fetchingContractorCodes}
                                    isMulti={false}
                                    formatOptionLabel={(option): string => option.name}
                                    getOptionValue={(option): string => option.value}
                                    getOptionLabel={(option): string => option.name}
                                    options={contractorCodes.map((cc) => {
                                        return { name: `${cc.name} | ${cc.description}`, value: cc.name };
                                    })}
                                    value={contractorCode}
                                    onChange={(value) => setContractorCode(value)}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label htmlFor="functionCode" sm={2}>
                                Function type
                            </Label>
                            <Col sm={10}>
                                <Select
                                    id="functionCode"
                                    isLoading={fetchingFunctionCodes}
                                    isMulti={false}
                                    formatOptionLabel={(option): string => option.name}
                                    getOptionValue={(option): string => option.value}
                                    getOptionLabel={(option): string => option.name}
                                    options={functionCodes.map((fc) => {
                                        return { name: `${fc.name} | ${fc.description}`, value: fc.name };
                                    })}
                                    value={functionCode}
                                    onChange={(value) => setFunctionCode(value)}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label htmlFor="preferredSieNumber" sm={2}>
                                Format preview
                            </Label>
                            <Col sm={10}>
                                <Input
                                    id="previewSieNumberPrefix"
                                    readOnly
                                    value={`${projectPrefix?.value?.length > 0 ? projectPrefix?.value?.toUpperCase() : 'PPPP'}-${functionCode?.value?.length > 0 ? functionCode.value : 'FFFF'
                                        }-NNNN`}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>

                        </FormGroup>
                        <FormGroup row>
                            <Col sm={12}>
                                <h3> Allocation types </h3>
                                <div className="radio">
                                    <Row>
                                        <Col>
                                            <Card className={`${allocationOption === 0 ? 'border border-primary' : null}`}>
                                                <CardHeader htmlFor="simple" onClick={() => setAllocationOption(0)}>
                                                    Simple allocation (First available)
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col sm={1} onClick={() => setAllocationOption(0)} >
                                                            <input
                                                                id="simple"
                                                                tabIndex={0}
                                                                type="radio"
                                                                checked={allocationOption === 0}
                                                                onChange={() => setAllocationOption(0)}
                                                            />
                                                        </Col>
                                                        <Col sm={2} onClick={() => setAllocationOption(0)}>
                                                            <Label htmlFor="quantityToAssign">
                                                                Quantity to assign
                                                            </Label>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Input
                                                                id="quantityToAssign"
                                                                type="number"
                                                                value={quantity}
                                                                min={1}
                                                                max={500}
                                                                onChange={quantityHandler}
                                                                disabled={allocationOption !== 0 ? true : false}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card className={`mt-3 ${allocationOption === 1 ? 'border border-primary' : null}`}>
                                                <CardHeader htmlFor="SequentialNumbers" onClick={() => setAllocationOption(1)}>
                                                    Sequential numbers
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col sm={1} onClick={() => setAllocationOption(1)} >
                                                            <input
                                                                id="SequentialNumbers"
                                                                tabIndex={0}
                                                                type="radio"
                                                                checked={allocationOption === 1}
                                                                onChange={() => setAllocationOption(1)}
                                                            />
                                                        </Col>
                                                        <Col sm={2} onClick={() => setAllocationOption(1)}>
                                                            <Label htmlFor="StartingNumber">
                                                                Start Number:
                                                            </Label>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Input
                                                                id="StartingNumber"
                                                                type="number"
                                                                value={startingNumber}
                                                                placeholder="0870"
                                                                min={0}
                                                                max={9999}
                                                                onChange={startNumberHandler}
                                                                disabled={allocationOption === 1 ? false : true}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-1">
                                                        <Col sm={{ size: 2, offset: 1 }} onClick={() => setAllocationOption(1)}>
                                                            <Label htmlFor="StartingNumber">
                                                                Quantity:
                                                            </Label>
                                                        </Col>
                                                        <Col sm={{ size: 4, offset: 0 }}>
                                                            <Input
                                                                id="quantityToAssign"
                                                                type="number"
                                                                value={quantity}
                                                                min={1}
                                                                max={500}
                                                                onChange={quantityHandler}
                                                                disabled={allocationOption === 1 ? false : true}
                                                            />
                                                        </Col>
                                                        {sequentialOverflow && allocationOption === 1 && (
                                                            <Col>
                                                                <Alert className="float-center" color="danger">
                                                                    Numbers greater than 9999 are not allowed
                                                                </Alert>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card className={`mt-3 ${allocationOption === 2 ? 'border border-primary' : null}`}>
                                                <CardHeader htmlFor="nonSequential" onClick={() => setAllocationOption(2)}>
                                                    Non-sequential Numbers
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col sm={1} onClick={() => setAllocationOption(2)} >
                                                            <input
                                                                id="nonSequential"
                                                                tabIndex={0}
                                                                type="radio"
                                                                checked={allocationOption === 2}
                                                                onChange={() => setAllocationOption(2)}
                                                            />
                                                        </Col>
                                                        <Col sm={4} onClick={() => setAllocationOption(2)} >
                                                            <Label htmlFor="CSN">Choose numbers between 0 and 9999, by writing them in separated by , (Comma)</Label>
                                                        </Col>
                                                        <Col>
                                                            <Input
                                                                placeholder="0050,0051,0060,0062"
                                                                type="textarea"
                                                                name="CSN"
                                                                id="CSN"
                                                                readOnly={allocationOption === 2 ? false : true}
                                                                pattern="[-, ,0-9]+"
                                                                title="Please only use numbers, separated by ','"
                                                                value={nonSequential}
                                                                onChange={e => setNonSequential(e.target.value)}
                                                                disabled={allocationOption === 2 ? false : true}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        {problematicNumbers.length > 0 && problematicNumbers[0] !== '' &&
                                                            <Col>
                                                                <h5>Invalid numbers: </h5>
                                                                {problematicNumbers
                                                                    .map((n) => (
                                                                        <Badge color="danger" key={n}>
                                                                            {n}
                                                                        </Badge>
                                                                    ))
                                                                }
                                                            </Col>
                                                        }
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card className={`mt-3 ${allocationOption === 3 ? 'border border-primary' : null}`}>
                                                <CardHeader htmlFor="suggestedNumbers" onClick={() => setAllocationOption(3)}>
                                                    Suggested Numbers
                                                </CardHeader>
                                                <CardBody>
                                                    <Row sm={12}>
                                                        <Col sm={1} onClick={() => setAllocationOption(3)}>
                                                            <input
                                                                id="suggestedNumbers"
                                                                tabIndex={0}
                                                                type="radio"
                                                                checked={allocationOption === 3}
                                                                onChange={() => setAllocationOption(3)}
                                                            />
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Label htmlFor="LowerLimit" onClick={() => setAllocationOption(3)}>
                                                                Lower limit:
                                                            </Label>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Input
                                                                id="LowerLimit"
                                                                type="number"
                                                                value={LowerLimit}
                                                                placeholder="23"
                                                                min={0}
                                                                max={9999}
                                                                onChange={lowerHandler}
                                                                disabled={allocationOption === 3 ? false : true}
                                                            />

                                                        </Col>
                                                        <Col sm={1}>
                                                            <Label htmlFor="StartingNumber">
                                                                Quantity:
                                                            </Label>
                                                        </Col>
                                                        <Col>
                                                            <Input
                                                                id="quantityToAssign"
                                                                type="number"
                                                                value={quantity}
                                                                min={0}
                                                                max={500}
                                                                onChange={quantityHandler}
                                                                disabled={allocationOption === 3 ? false : true}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={{ size: 2, offset: 1 }} onClick={() => setAllocationOption(3)}>
                                                            <Label htmlFor="UpperLimit">
                                                                Upper limit:
                                                            </Label>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Input className="mt-1"
                                                                id="UpperLimit"
                                                                type="number"
                                                                value={UpperLimit}
                                                                placeholder="29"
                                                                min={0}
                                                                max={9999}
                                                                onChange={upperHandler}
                                                                disabled={allocationOption === 3 ? false : true}
                                                            />
                                                        </Col>
                                                        <Col sm={5}>
                                                            <Row className="mt-1 mr-1 float-right">
                                                                <Button
                                                                    onClick={fetchRanges} color="primary" disabled={allocationOption !== 3 || quantity < 1 || !inputValid || !UpperLimit} >
                                                                    Get suggested ranges </Button>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    {suggestedOverflow && (
                                                        <Col>
                                                            <Alert className="float-center mt-2" color="danger">
                                                                Difference between Lower limit and Upper limit can not be greater than 1000
                                                            </Alert>
                                                        </Col>)}
                                                    <Row className="mt-2 justify-content-center">
                                                        <Col sm="col-sm-auto" className="align-center">
                                                            {fetchingRanges && <Spinner size="sm" />}
                                                            {!rangeError && ranges?.availableRanges
                                                                .map((n, i) => (
                                                                    <Button className="m-1 " color="primary" key={i} disabled={allocationOption !== 3} onClick={() => setRange(i)}>
                                                                        {n.startingSequenceNumber} - {n.endingSequenceNumber}
                                                                    </Button>
                                                                )).reduce((prev, curr) => [prev, ' ', curr])}
                                                        </Col>
                                                    </Row>
                                                    {rangeError && (
                                                        <Alert className="float-center mt-2" color="danger">
                                                            {rangeError}
                                                        </Alert>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </FormGroup>
                        <Row>
                            <Col>
                                <Card className="mt-3">
                                    <CardHeader>
                                        <Label htmlFor="Preview">Preview</Label>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                {previewError && (
                                                    <Badge color="danger">
                                                        {previewError}
                                                    </Badge>)}
                                                {preview && !previewError && !sequentialOverflow && preview?.length !== 0 && preview.map((n) => (
                                                    <Badge color={availabilityError ? 'danger' : availabilityResponse ? 'success' : 'primary'} key={n}>
                                                        {
                                                            projectPrefix?.value + '-' + functionCode?.value + '-' + _.padStart((n).toString(), 4, '0')}
                                                    </Badge>
                                                )).reduce((prev, curr) => [prev, ' ', curr])}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Button className="mt-2 ml-3" onClick={availabilityCheck}
                                                disabled={preview?.length < 1 || previewError?.length > 1 || sequentialOverflow || !inputValid} color="primary">
                                                Check availability of {preview?.length > 1 ? 'these' : 'this'} SIE number{quantity > 1 && 's'}{availabilityChecking && <Spinner size="sm" />}
                                            </Button>
                                            <Button className="mt-2 ml-3" color="primary" onClick={RemovePreviewResult}
                                                disabled={preview?.length < 1 || previewError?.length > 1 || sequentialOverflow || !inputValid}>
                                                Clear preview
                                            </Button>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                {availabilityResponse && (
                                                    <Alert className="float-center" color="success">
                                                        {availabilityResponse}
                                                    </Alert>
                                                )}
                                                {availabilityError && (
                                                    <Alert className="float-center" color="danger">
                                                        {availabilityError}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mt-3" >
                            <Col>
                                <Button className="float-right" color="primary" onClick={allocate} disabled={allocating || !inputValid || sequentialOverflow}>
                                    Allocate SIE number{quantity > 1 && 's'} {allocating && <Spinner size="sm" />}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    {error && (
                        <div className="mt-2">
                            <RenderError error={error} toggle={(): void => setError(null)} />
                        </div>
                    )}
                    {allocationErrors && (
                        <div className="mt-2">
                            <RenderError error={allocationErrors} toggle={(): void => setAllocationErrors(null)} />
                        </div>
                    )}
                </CardBody>
            </Card>
            {allocationResult?.allocatedSieNumbers && (
                <Card style={{ marginTop: '2em' }}>
                    <CardHeader>
                        <h3 className="float-left">Allocated numbers</h3>
                        <div className="float-right">
                            <Button className="float-left" color="primary" onClick={RemoveAllocatedResult}>
                                Clear allocations
                            </Button>
                            <ExportExcel data={allocated}
                                filename={'SieNumbers_' + projectPrefix?.value + '-' + functionCode?.value + '_' + new Date(Date.now()).toLocaleDateString('en-GB').toString()} />
                        </div>
                    </CardHeader>
                    <CardBody>
                        {error && <Alert color="danger">{error}</Alert>}
                        {allocationResult?.allocatedSieNumbers.count === 0 && <i>No allocated SIE numbers allocated</i>}
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <a>Facility</a>
                                    </th>
                                    <th>
                                        <a>SIE Number</a>
                                    </th>
                                    <th>
                                        <a>Project</a>
                                    </th>
                                    <th>
                                        <a>SIE Project Prefix</a>
                                    </th>
                                    <th>
                                        <a>Function Type</a>
                                    </th>
                                    <th>
                                        <a>Contractor Code</a>
                                    </th>
                                    <th>
                                        <a>SAP Equipment No</a>
                                    </th>
                                    <th>
                                        <a>Status</a>
                                    </th>
                                    <th>
                                        <a>Created</a>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {mappedAllocatedObjects?.map((n) => (
                                    <tr key={n.sieNumber}>
                                        <td>{n.facility}</td>
                                        <td>
                                            <Badge color="success">{n?.sieNumber?.toUpperCase()}</Badge>
                                        </td>
                                        <td>{n.project}</td>
                                        <td>{n.projectPrefix}</td>
                                        <td>{n.functionCode}</td>
                                        <td>{n.contrCode}</td>
                                        <td>{n.sapEquipmentNo}</td>
                                        <td>{n.status}</td>
                                        <td>{new Date(Date.parse(n.created)).toLocaleString('en-GB')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            )}
            <Modal isOpen={showExcessiveQuantityConfirmation} size="lg">
                <ModalHeader>Excessive allocation</ModalHeader>
                <ModalBody>
                    <p>You are allocating an excessive amount of equipment numbers, are you sure you want to continue?</p>
                    <p>
                        Numbers to allocate: <b>{quantity}</b>
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={(): void => continueAllocation(false)}>No</Button>
                    <Button onClick={(): void => continueAllocation(true)} color="primary">
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};


export default EquipmentNumberAllocation;
