import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';

declare global {
    interface Window {
        devToolsExtension: any; // Needed to add this to make typescript build
    }
}

export default function configureStore(history, initialState) {
    const reducers = {};

    const middleware = [thunk, routerMiddleware(history)];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const createRootReducer = (routerHistory) =>
        combineReducers({
            router: connectRouter(routerHistory), // Note: this key MUST be router, ref: https://github.com/supasate/connected-react-router
            ...reducers // rest of your reducers
        });

    return createStore(createRootReducer(history), initialState, compose(applyMiddleware(...middleware), ...enhancers));
}
