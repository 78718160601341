import { Error } from '../models/Error';
import { Action, action, thunk, Thunk } from 'easy-peasy';
import * as api from '../services/Api';
import { createError } from '../components/sie-components/Errors';
import _ from 'lodash';
import { SieObject } from '../models/SieObject';
import Project from '../models/Project';
import { SieUpdateRequest } from '../models/SieUpdateRequest';

export interface SieObjectModel {
    sieObjects: Array<SieObject>;
    setSelectedSieObjects: Action<SieObjectModel, Array<SieObject>>;

    fetchingSieObject: boolean;
    setFetchingSieObject: Action<SieObjectModel, boolean>;

    updateSieObject: Thunk<SieObjectModel, SieUpdateRequest>;
    updatingSieObject: boolean;
    setUpdatingSieObject: Action<SieObjectModel, boolean>;
    syncSavedSieObject: Action<SieObjectModel, SieObject>;

    sort: Action<SieObjectModel>;

    selectedSieObject: SieObject;
    setSelectedSieObject: Action<SieObjectModel, SieObject>;

    project: Project;
    setProject: Action<SieObjectModel, Project>;

    success: boolean;
    setSuccess: Action<SieObjectModel, boolean>;

    updateError: string;
    setUpdateError: Action<SieObjectModel, Error | any>;

    setError: Action<SieObjectModel, Error | any>;
}

const sieObject: SieObjectModel = {
    success: false,
    updateError: null,
    sieObjects: null,
    fetchingSieObject: false,
    updatingSieObject: false,
    project: null,
    setFetchingSieObject: action((state, payload) => {
        state.fetchingSieObject = payload;
    }),
    setSelectedSieObject: action((state, payload) => {
        state.selectedSieObject = payload;
    }),

    setUpdateError : action((state, payload) => {
        state.updateError =payload;
    }),

    setSuccess : action((state, payload) => {
        state.success =payload;
    }),

    sort: action((state) => {
        state.sieObjects = _.sortBy(state.sieObjects, 'Created')
    }),

    selectedSieObject: null,

    updateSieObject: thunk(async (actions, selectedSieObject, __) => {
        actions.setUpdatingSieObject(true);
        actions.setUpdateError(null);
        actions.setSuccess(false);
        await api
            .SieObjectUpdate(selectedSieObject)
            .then(async (result: any) => {
                if(!result.error) {
                    actions.setSuccess(true)
                    actions.syncSavedSieObject(result);
                } else  {
                    actions.setUpdateError(result.error);
                    actions.setSuccess(false);
                }
            })
            .catch((reason) => {
                actions.setUpdateError(createError('SieObject', 'Could not update SIE Equipment number', reason));
            })
            .finally(() => {
                actions.setUpdatingSieObject(false);

            });
    }),
    syncSavedSieObject: action((state, updatedSieObject) => {

        const toBeUpdatedInSieObject = _.findIndex(state.sieObjects, f => {
            return f.sieNumber === updatedSieObject.sieNumber
        }, 0);

        if (toBeUpdatedInSieObject >= 0) {
            _.set(state.sieObjects, [toBeUpdatedInSieObject], updatedSieObject)
        }
    }),

    setError: action((_n, __) => { }),

    setUpdatingSieObject: action((state, payload) => {
        state.updatingSieObject = payload;
    }),
    setSelectedSieObjects: action((state, payload) => {
        state.sieObjects = payload;
    }),
    setProject: action((state, payload) => {
        state.project = payload;
    })
};

export default sieObject;
