export enum NewsType {
    SieNews = 'SieNews',
    SieAlert = 'SieAlert'
}

export enum AlertSeverity {
    Info = 'Info',
    Success = 'Success',
    Warning = 'Warning',
    Error = 'Error'
}

export interface NewsEntry {
    id: string;
    title: string;
    content: string;
    type: NewsType;
    alertSeverity?: AlertSeverity;
    publishDate: Date;
    publishedBy: string;
    published: boolean;
}
