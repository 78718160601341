import { EffectCallback, useEffect, useState } from 'react';
import { useStoreState } from './store/PortalStore';

// Used to make code more readable, and avoid eslint warnings due to missing dependencies - use responsibly
// Ref: https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once/56767883#56767883
export const useMountEffect = (fun: EffectCallback): void => useEffect(fun, []);

export const useTitle = (title: string) => {
    const config = useStoreState((state: { global: { config: any } }) => state.global.config);

    const name = 'SIE Portal';

    const [siteName, setSiteName] = useState(name);

    useEffect(() => {
        setSiteName(`${name} ${config && config.environment ? config.environment : ''}`);
    }, [config]);

    useEffect(() => {
        if (!title || title.trim().length === 0) {
            document.title = siteName;
            return;
        }

        document.title = `${title} | ${siteName}`;
    }, [title, siteName]);
};
